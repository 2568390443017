import axios from "axios";
import React, { useState, useRef, useEffect } from "react";
import TransactionStatus from "../Arabic/studentPage/transactionStatus";

export default function Paypal({ checkout }) {
  let baseURL = "https://api.eshraqalazhar.com/students";

  const [paidFor, setPaidFor] = useState(false);
  const [error, setError] = useState(null);
  const paypalRef = useRef();

  useEffect(() => {
    window.paypal
      .Buttons({
        createOrder: (data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                description: checkout.date,
                amount: {
                  value: checkout.amount,
                },
              },
            ],
          });
        },
        onApprove: async (data, actions) => {
          const order = await actions.order.capture();
          if (checkout.date) {
            axios
              .put(`${baseURL}/${checkout.studentObj._id}`, checkout.studentObj)
              .then((response) => {
                setPaidFor(true);
              })
              .catch((error) => {
                console.log(error);
                setPaidFor(true);
              });
          }
          if (!checkout.date) {
            setPaidFor(true);
          }
        },
        onError: (err) => {
          setError(err.message);
          console.error(err);
        },
      })
      .render(paypalRef.current);
  }, [checkout.amount]);

  if (paidFor) {
    return <TransactionStatus status={true} />;
  }

  return (
    <div>
      {error && <TransactionStatus status={false} />}
      {!error && (
        <>
          {checkout.date && (
            <h1>
              دفع فاتورة شهر {checkout.date} بمبلغ {checkout.amount}
              {checkout.currency}
            </h1>
          )}

          <div ref={paypalRef} />
        </>
      )}
    </div>
  );
}
