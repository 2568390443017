import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import styles from "../../../../css/scheduleTable.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import { Form, Button, Col } from "react-bootstrap";
import {
  faBookOpen,
  faUser,
  faClock,
  faLink,
  faComment,
  faBell,
  faCheckCircle,
  faQuestionCircle,
  faRedoAlt,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

export default function NotCompleted() {
  let baseURL = "https://api.eshraqalazhar.com/classes";
  const [classes, setClasses] = useState([]);

  useEffect(() => {
    axios
      .get(`${baseURL}`)
      .then((response) => {
        setClasses(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const notcompletedClasses = classes.filter(
    (classObj) =>
      classObj.isStarted === false &&
      days.indexOf(classObj.startTime.split(" ")[0]) == new Date().getDay()
  );
  //pagination:
  const [CurrentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 5;
  const lastIndex = CurrentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = notcompletedClasses.slice(firstIndex, lastIndex);
  const npage = Math.ceil(notcompletedClasses.length / recordsPerPage);

  const numbers = [...Array(npage).keys()];
  return (
    <div className={styles.parentTable}>
      <Table className={styles.tableDirction}>
        <thead>
          <tr className={styles.head}>
            <th>
              <FontAwesomeIcon className={styles.tableIcon} icon={faBookOpen} />{" "}
              المسلسل
            </th>
            <th>
              <FontAwesomeIcon className={styles.tableIcon} icon={faUser} /> اسم
              المعلم
            </th>
            <th>
              <FontAwesomeIcon className={styles.tableIcon} icon={faUser} /> اسم
              الطالب
            </th>
            <th>
              <FontAwesomeIcon className={styles.tableIcon} icon={faBookOpen} />{" "}
              موضوع الحصة
            </th>
            <th>
              <FontAwesomeIcon className={styles.tableIcon} icon={faClock} />{" "}
              وقت الحصة
            </th>
            <th>
              <FontAwesomeIcon className={styles.tableIcon} icon={faClock} />{" "}
              المدة
            </th>
          </tr>
        </thead>
        <tbody>
          {records.map((cls, index) => {
            let { _id, student, teacher, startTime, classDuration, subject } =
              cls;

            return (
              <tr className="" key={_id}>
                <td>{index + 1}</td>
                <td>
                  {teacher.firstName} {teacher.lastName}
                </td>
                <td>
                  {student.firstName} {student.lastName}
                </td>
                <td>{subject}</td>
                <td>{startTime}</td>
                <td>{classDuration}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <nav className="d-flex justify-content-center align-items-center">
        <ul className="pagination">
          <li className="page-item">
            <a href="#" className="page-link text-dark" onClick={prePage}>
              السابق
            </a>
          </li>
          {numbers.map((n, i) => {
            return (
              <li
                className={`page-item ${CurrentPage === n + 1 ? "active" : ""}`}
                key={i}
              >
                <a
                  href="#"
                  className="page-link text-dark "
                  onClick={() => changePage(n + 1)}
                >
                  {n + 1}
                </a>
              </li>
            );
          })}

          <li className="page-item">
            <a href="#" className="page-link text-dark" onClick={nextPage}>
              التالي
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
  function prePage(e) {
    if (CurrentPage !== firstIndex + 1) {
      setCurrentPage(CurrentPage - 1);
    }
  }
  function nextPage() {
    if (CurrentPage !== numbers.length) {
      setCurrentPage(CurrentPage + 1);
    }
  }
  function changePage(id) {
    setCurrentPage(id);
  }
}
