import axios from "axios";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import styles from "../../../css/scheduleTable.module.css";
import { Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faClock,
  faLink,
  faQuestionCircle,
  faUser,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";

export default function DashboardTeachers() {
  let baseURL = "https://api.eshraqalazhar.com/teachers";
  let navigate = useNavigate();
  const [teachers, setTeachers] = useState([]);
  const [confirmString, setConfirmString] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [window.location.pathname]);

  async function fetchData() {
    axios
      .get(baseURL)
      .then((response) => {
        setTeachers(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  //start
  const [deletedTeacher, setDeletedTeacher] = useState([]);

  useEffect(() => {
    if (deletedTeacher) {
      setTeachers(
        teachers.filter((teacher) => teacher._id != deletedTeacher._id)
      );
    }
  }, [deletedTeacher]);

  //End
  const dateAndTime = new Date();

  //pagination:
  const [CurrentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 3;
  const lastIndex = CurrentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = teachers.slice(firstIndex, lastIndex);
  const npage = Math.ceil(teachers.length / recordsPerPage);
  const numbers = [...Array(npage).keys()];

  return (
    <div className={styles.parentTable}>
      <div className="add-teacher">
        <NavLink
          to={"/dashboard/teacherForm"}
          className={`${styles.stBtn} btn btn-success mb-3 align-items-center `}
        >
          إضافة معلم
        </NavLink>
      </div>
      <Table className={styles.tableDirction}>
        <thead>
          <tr className={styles.head}>
            <th colSpan={2}></th>

            <th>
              <FontAwesomeIcon className={styles.tableIcon} icon={faUser} />
              الاسم
            </th>
            <th>
              <FontAwesomeIcon className={styles.tableIcon} icon={faPhone} />
              رقم الهاتف
            </th>
            <th>
              <FontAwesomeIcon className={styles.tableIcon} icon={faPhone} />
              الإيميل
            </th>
            <th>
              <FontAwesomeIcon
                className={styles.tableIcon}
                icon={faQuestionCircle}
              />
              السن
            </th>
            <th>
              <FontAwesomeIcon className={styles.tableIcon} icon={faClock} />
              النوع
            </th>
            <th>
              <FontAwesomeIcon className={styles.tableIcon} icon={faClock} />
              البلد
            </th>
            <th>
              <FontAwesomeIcon
                className={styles.tableIcon}
                icon={faCheckCircle}
              />
              تارخ البدء
            </th>
            <th>
              <FontAwesomeIcon className={styles.tableIcon} icon={faLink} />
              إيميل الزووم
            </th>
            <th colSpan={7}>
              <FontAwesomeIcon className={styles.tableIcon} icon={faLink} />
              الأيام المتاحة
            </th>
          </tr>
        </thead>
        <tbody>
          {records.map((teacher, index) => {
            let {
              firstName,
              lastName,
              email,
              age,
              gender,
              joiningDate,
              cellPhoneNumber,
              zoomEmailID,
              city,
              country,
              _id,
              availableTimes,
            } = teacher;

            let deleteTeacher = () => {
              const promptString = prompt("اكتب 'حذف' لتأكيد عملية الحذف:");
              setConfirmString(promptString);
              if (promptString === "حذف") {
                // Perform delete operation
                axios
                  .delete(`${baseURL}/${_id}`)
                  .then((response) => {
                    setDeletedTeacher(teacher);
                    alert("جاري الحذف");
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              } else {
                alert("لم يتم كتابتها بشكل صحيح");
              }
            };

            let editTeacher = () => {
              navigate(`/dashboard/teacherForm/${_id}`);
            };

            let showEarning = () => {
              navigate(`/dashboard/teacherAccounting/${_id}`);
            };

            let showStudents = () => {
              navigate(`/dashboard/students/${_id}`);
            };
            let showClasses = () => {
              navigate(`/dashboard/classes/all/student/teacher/${_id}`);
            };

            let daysOfWeek = availableTimes.map((availableTime) => {
              return `اليوم: ${availableTime.dayOfWeek}\n\n  من:${availableTime.startTime} إلى:${availableTime.endTime}`;
            });
            return (
              <tr className="" key={index}>
                <td>
                  <button className="btn btn-info mt-2" onClick={showStudents}>
                    الطلاب
                  </button>
                  <button
                    className="btn btn-success mt-2"
                    onClick={showClasses}
                  >
                    الحصص
                  </button>
                </td>
                <td>
                  <button className="btn btn-warning" onClick={editTeacher}>
                    تعديل
                  </button>
                  <button
                    className="btn btn-danger mt-2"
                    onClick={deleteTeacher}
                  >
                    حذف
                  </button>
                  <button
                    className="btn btn-primary mt-2"
                    onClick={showEarning}
                  >
                    حسابات
                  </button>
                </td>
                <td>
                  {firstName} {lastName}
                </td>
                <td>
                  <a
                    target="_blank"
                    href={`https://api.whatsapp.com/send?phone=${cellPhoneNumber}`}
                  >
                    {cellPhoneNumber}
                  </a>
                </td>
                <td>{email}</td>
                <td>{age}</td>
                <td>{gender == "male" ? "ذكر" : "أنثى"}</td>
                <td>
                  {country} {"/"} {city}
                </td>
                <td>{joiningDate}</td>
                <td>{zoomEmailID}</td>
                {daysOfWeek.map((dayOfWeek, index) => {
                  return <td key={index}>{dayOfWeek}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
      <nav
        className={`${styles.stBtn} d-flex justify-content-center align-items-center`}
      >
        <ul className="pagination">
          <li className="page-item">
            <a href="#" className="page-link text-dark" onClick={prePage}>
              السابق
            </a>
          </li>
          {numbers.map((n, i) => {
            return (
              <li
                className={`page-item ${CurrentPage === n + 1 ? "active" : ""}`}
                key={i}
              >
                <a
                  href="#"
                  className="page-link text-dark "
                  onClick={() => changePage(n + 1)}
                >
                  {n + 1}
                </a>
              </li>
            );
          })}
          <li className="page-item">
            <a href="#" className="page-link text-dark" onClick={nextPage}>
              التالي
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
  function prePage(e) {
    if (CurrentPage !== firstIndex + 1) {
      setCurrentPage(CurrentPage - 1);
    }
  }
  function nextPage() {
    if (CurrentPage !== numbers.length) {
      setCurrentPage(CurrentPage + 1);
    }
  }
  function changePage(id) {
    setCurrentPage(id);
  }
}
