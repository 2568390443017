import React, { useState } from "react";
import { Form, Button, Col } from "react-bootstrap";
import { useLogin } from "../../hooks/useLogin"; //
import "../../css/RegistrationFormAr.css";
import { Navigate } from "react-router-dom";

export default function AdminLogin() {
  const { login, error, isLoading } = useLogin();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
      await login(formData.email, formData.password, "admin");
    
  };

  return (
    <div className="registration-form my-5">
      <h2 className="mb-5"> تسجيل دخول المسؤول</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="form-row">
          <Form.Label>: البريد الإلكتروني </Form.Label>
          <Col sm={10}>
            <Form.Control
              style={{ direction: "rtl" }}
              type="text"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </Col>
        </Form.Group>
        <Form.Group className="form-row mt-2">
          <Form.Label>: الباسورد </Form.Label>
          <Col sm={10}>
            <Form.Control
              style={{ direction: "rtl" }}
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
            />
          </Col>
        </Form.Group>

        <div className="text-center mt-4">
          <Button
            variant="success"
            type="submit"
            className="submit-btn"
            disabled={isLoading}
          >
            تسجيل دخول
          </Button>
          <div className="errorMsgDiv">
            {error && (
              <div className="error">
                {error && (
                  <div className="error">
                    {error == "كلمة المرور او البريد الإلكتروني غير صحيحة"
                      ? error
                      : "خطأ في الشبكة! برجاء المحاولة مرة أخرى"}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </Form>
    </div>
  );
}
