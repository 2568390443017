import axios from "axios";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import styles from "../../../css/scheduleTable.module.css";
import { Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

import {
  faCheckCircle,
  faClock,
  faLink,
  faPhone,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

export default function DashboardStudents() {
  let baseURL = "https://api.eshraqalazhar.com/students";
  let navigate = useNavigate();
  let { id } = useParams();
  const [students, setStudents] = useState([]);
  const [confirmString, setConfirmString] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [window.location.pathname]);

  async function fetchData() {
    axios
      .get(baseURL)
      .then((response) => {
        if (id) {
          setStudents(
            response.data.filter((student) => student.teacher._id == id)
          );
        } else {
          setStudents(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const [deletedStudent, setDeletedStudent] = useState([]);

  useEffect(() => {
    if (deletedStudent) {
      setStudents(
        students.filter((student) => student._id != deletedStudent._id)
      );
    }
  }, [deletedStudent]);

  //pagination:
  const [CurrentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 3;
  const lastIndex = CurrentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = students.slice(firstIndex, lastIndex);
  const npage = Math.ceil(students.length / recordsPerPage);
  const numbers = [...Array(npage).keys()];

  return (
    <div className={styles.parentTable}>
      <NavLink
        to={"/dashboard/studentForm/student/way"}
        className={`${styles.stBtn} btn btn-success mb-3 align-items-center `}
      >
        إضافة طالب
      </NavLink>
      <Table className={styles.tableDirction}>
        <thead>
          <tr className={styles.head}>
            <th colSpan={2}></th>
            <th>
              <FontAwesomeIcon className={styles.tableIcon} icon={faUser} /> اسم
              الطالب
            </th>
            <th>
              <FontAwesomeIcon className={styles.tableIcon} icon={faPhone} />
              رقم الطالب
            </th>
            <th>
              <FontAwesomeIcon className={styles.tableIcon} icon={faUser} /> اسم
              المعلم
            </th>
            <th>
              <FontAwesomeIcon className={styles.tableIcon} icon={faEnvelope} />{" "}
              الرسائل
            </th>
            <th>
              <FontAwesomeIcon className={styles.tableIcon} icon={faUser} />
              الإيميل
            </th>
            <th>
              <FontAwesomeIcon className={styles.tableIcon} icon={faUser} />
              السن
            </th>
            <th>
              <FontAwesomeIcon className={styles.tableIcon} icon={faUser} />
              النوع
            </th>
            <th>
              <FontAwesomeIcon className={styles.tableIcon} icon={faClock} />
              البلد
            </th>
            <th>
              <FontAwesomeIcon
                className={styles.tableIcon}
                icon={faCheckCircle}
              />
              تاريخ البدء
            </th>
            <th>
              <FontAwesomeIcon
                className={styles.tableIcon}
                icon={faCheckCircle}
              />
              خطة الاشتراك
            </th>
            <th>
              <FontAwesomeIcon className={styles.tableIcon} icon={faLink} />
              لينك الحصة
            </th>
            <th colSpan={7}>
              <FontAwesomeIcon
                className={styles.tableIcon}
                icon={faCheckCircle}
              />
              المواعيد المتاحة
            </th>
          </tr>
        </thead>
        <tbody>
          {records.map((student, index) => {
            let {
              firstName,
              lastName,
              email,
              age,
              gender,
              joiningDate,
              cellPhoneNumber,
              classZoomLink,
              city,
              country,
              planFees,
              _id,
              teacher,
              weeklyClasses,
            } = student;

            let showEarning = () => {
              navigate(`/dashboard/studentAccounting/${_id}`);
            };

            let showFeedbackHistory = () => {
              navigate(`/dashboard/feedbackHistory/${_id}`);
            };
            let showClasses = () => {
              navigate(`/dashboard/classes/all/student/${_id}/teacher`);
            };

            let deleteStudent = () => {
              const promptString = prompt("اكتب 'حذف' لتأكيد عملية الحذف:");
              setConfirmString(promptString);
              if (promptString === "حذف") {
                // Perform delete operation
                axios
                  .delete(`${baseURL}/${_id}`)
                  .then((response) => {
                    setDeletedStudent(student);
                    alert("تم حذف بيانات الطالب وجميع حصصه");
                  })
                  .catch((error) => {
                    console.log(error);
                  });
                // delete all related classes
                axios
                  .delete(`${baseURL}/${_id}/classes`)
                  .then((response) => {})
                  .catch((error) => {
                    console.log(error);
                  });
              } else {
                alert("لم يتم كتابتها بشكل صحيح");
              }
            };

            let editStudent = () => {
              navigate(`/dashboard/studentForm/student/${_id}/way`);
            };

            let daysOfWeek = weeklyClasses.map((weekday) => {
              return `اليوم: ${weekday.dayOfWeek} \n\n بداية الحصة:${weekday.startTime} المدة:${weekday.duration}`;
            });

            return (
              <tr key={index}>
                <td>
                  <button
                    className="btn btn-success mt-2"
                    onClick={showClasses}
                  >
                    الحصص
                  </button>
                  <button
                    className="btn btn-info mt-2"
                    onClick={showFeedbackHistory}
                  >
                    تعليقات
                  </button>
                  <button
                    className="btn btn-primary mt-2"
                    onClick={showEarning}
                  >
                    حسابات
                  </button>
                </td>
                <td>
                  <NavLink
                    to={`/dashboard/classes/classForm/student/${_id}/class`}
                    className="btn btn-success mb-2"
                  >
                    إضافة حصة
                  </NavLink>
                  <button
                    className="btn btn-warning mt-2"
                    onClick={editStudent}
                  >
                    تعديل
                  </button>
                  <button
                    className="btn btn-danger mt-2"
                    onClick={deleteStudent}
                  >
                    حذف
                  </button>
                </td>
                <td>
                  {firstName} {lastName}
                </td>
                <td>
                  <a
                    target="_blank"
                    href={`https://api.whatsapp.com/send?phone=${cellPhoneNumber}`}
                  >
                    {cellPhoneNumber}
                  </a>
                </td>
                <td>
                  {teacher?.firstName} {teacher?.lastName}
                </td>
                <td>
                  <button
                    onClick={() =>
                      navigate(`/dashboard/chat/${_id}`, {
                        state: { teacher, firstName, lastName },
                      })
                    }
                    className={"chat-icon"}
                  >
                    <strong>
                      <FontAwesomeIcon icon={faEnvelope} className="fs-4" />
                    </strong>
                  </button>
                </td>
                <td>{email}</td>
                <td>{age}</td>
                <td>{gender == "male" ? "ذكر" : "أنثى"}</td>
                <td>
                  {country} {"/"} {city}
                </td>
                <td>{joiningDate}</td>
                <td>{planFees}</td>
                <td>
                  <a target="_blank" href={classZoomLink}>
                    {classZoomLink}
                  </a>
                </td>
                {daysOfWeek.map((dayOfWeek, i) => {
                  return <td key={i}>{dayOfWeek}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
      <nav
        className={` ${styles.stBtn} d-flex justify-content-center align-items-center`}
      >
        <ul className="pagination">
          <li className="page-item">
            <a href="#" className="page-link text-dark" onClick={prePage}>
              السابق
            </a>
          </li>
          {numbers.map((n, i) => {
            return (
              <li
                className={`page-item ${CurrentPage === n + 1 ? "active" : ""}`}
                key={i}
              >
                <a
                  href="#"
                  className="page-link text-dark "
                  onClick={() => changePage(n + 1)}
                >
                  {n + 1}
                </a>
              </li>
            );
          })}

          <li className="page-item">
            <a href="#" className="page-link text-dark" onClick={nextPage}>
              التالي
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
  function prePage(e) {
    if (CurrentPage !== firstIndex + 1) {
      setCurrentPage(CurrentPage - 1);
    }
  }
  function nextPage() {
    if (CurrentPage !== numbers.length) {
      setCurrentPage(CurrentPage + 1);
    }
  }
  function changePage(id) {
    setCurrentPage(id);
  }
}
