import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { chatAxios } from "../../config/chatAxios";

export const createConversation = createAsyncThunk(
  "conversations/create",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await chatAxios.post("/conversation", data); // data: { senderId: "", recieverId: ""}
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getAllConversations = createAsyncThunk(
  "conversations/all",
  async (userId, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await chatAxios.get(`/conversation/${userId}`);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  loading: false,
  success: false,
  error: null,
  conversationData: null,
  allConversationsData: [],
};

const conversationSlice = createSlice({
  name: "con",
  initialState,
  // reducers: [],
  extraReducers: (builder) => {
    builder.addCase(createConversation.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(createConversation.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.conversationData = action.payload;
    });
    builder.addCase(createConversation.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.conversationData = null;
      state.error = action.payload;
    });
    builder.addCase(getAllConversations.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getAllConversations.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.allConversationsData = action.payload;
    });
    builder.addCase(getAllConversations.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.allConversationsData = null;
      state.error = action.payload;
    });
  },
});

export default conversationSlice.reducer;
