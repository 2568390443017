import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import styles from "../../../css/scheduleTable.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { Form, Button, Col } from "react-bootstrap";

import {
  faBookOpen,
  faUser,
  faClock,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

export default function TeacherClasses() {
  const { user } = useAuthContext();
  const teacherID = user.teacher;
  let baseURL = "https://api.eshraqalazhar.com";
  const [classes, setClasses] = useState([]);

  const [isButtonDisabled, setIsButtonDisabled] = useState(false); //

  const [isLoading, setIsLoading] = useState(null);

  const [feedbacks, setFeedbacks] = useState({});

  const handleFeedbackChange = (e, classId) => {
    const { value } = e.target;
    setFeedbacks((prevFeedbacks) => ({
      ...prevFeedbacks,
      [classId]: value,
    }));
  };

  const addTeacherFeedback = (classId, subject, studentID) => {
    var today = new Date();
    var days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    var dayName = days[today.getDay()];
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var yyyy = today.getFullYear();
    today = `${dayName} ${dd}/${mm}/${yyyy}`;
    //
    const teacherFeedback = {
      subject,
      date: today,
      feedback: feedbacks[classId],
    };
    axios
      .post(`${baseURL}/students/${studentID}/updateFeedbackHistory`, {
        teacherFeedback,
      })
      .then((response) => {
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateAccounting = (classDuration, studentID) => {
    // update Teacher Earning History
    axios
      .post(`${baseURL}/teachers/${teacherID}/updateEarningHistory`, {
        minutes: classDuration,
      })
      .then((response) => {})
      .catch((error) => {
        console.error(error);
      });

    // update Student Accounting History
    axios
      .post(`${baseURL}/students/${studentID}/updateAccountingHistory`, {
        minutes: classDuration,
      })
      .then((response) => {})
      .catch((error) => {
        console.error(error);
      });
  };

  const handleStartClick = (classId, studentId, classDuration, startTime) => {
    // check startTime

    // Get the time string to check
    const timeToCheck = startTime;

    // Convert the time string to a Date object
    const [dayOfWeek, time] = timeToCheck.split(" ");
    const [hour, minute] = time.split(":");
    const dateToCheck = new Date();
    dateToCheck.setHours(hour);
    dateToCheck.setMinutes(minute);
    dateToCheck.setDate(
      dateToCheck.getDate() +
        ((7 +
          [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
          ].indexOf(dayOfWeek) -
          dateToCheck.getDay()) %
          7)
    );

    // Get the current time
    const now = new Date();

    // Check if the day matches
    if (now.getDay() !== dateToCheck.getDay()) {
      alert("أنت على وشك بدأ الحصة في غير يومها");
      startClass(); //
    } else {
      // Check if the time matches
      const timeDifferenceInMinutes = (now - dateToCheck) / 1000 / 60;

      if (timeDifferenceInMinutes === 0) {
        alert("بدأ الحصة في موعدها");
        startClass();
      } else if (timeDifferenceInMinutes > 0 && timeDifferenceInMinutes <= 15) {
        alert("بدأ الحصة");
        startClass();
      } else if (timeDifferenceInMinutes > 15) {
        alert("بدأ الحصة بعد موعدها بأكثر من 15 دقيقة"); // too late
        startClass();
      } else if (
        timeDifferenceInMinutes < 0 &&
        timeDifferenceInMinutes >= -15
      ) {
        alert("بدأ الحصة");
        startClass();
      } else {
        alert("بدأ الحصة قبل موعدها بأكثر من 15 دقيقة"); // too early
        startClass();
      }
    }

    function startClass() {
      //
      const isStarted = true;
      const isCompleted = false;

      //
      setIsButtonDisabled(true);
      // const expirationTime = Date.now() + 5000; // 1000 milliseconds = 1 second
      const expirationTime = Date.now() + 1 * 60 * 1000; // 1000 milliseconds = 1 second
      localStorage.setItem("expirationTime", expirationTime);

      axios
        .put(`${baseURL}/teachers/${teacherID}/classes/${classId}`, {
          isStarted,
          isCompleted,
        })
        .then((response) => {
          // update the state to reflect the added feedback
          setClasses((prevClasses) => {
            const updatedClasses = [...prevClasses];
            const classIndex = updatedClasses.findIndex(
              (cls) => cls._id === classId
            );
            updatedClasses[classIndex].isStarted = isStarted;
            updatedClasses[classIndex].isCompleted = isCompleted;
            return updatedClasses;
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleCancelClass = (classId) => {
    //
    const isStarted = false;
    const isCompleted = true;
    //
    axios
      .put(`${baseURL}/teachers/${teacherID}/classes/${classId}`, {
        isStarted,
        isCompleted,
      })
      .then((response) => {
        // update the state to reflect the added feedback
        setClasses((prevClasses) => {
          const updatedClasses = [...prevClasses];
          const classIndex = updatedClasses.findIndex(
            (cls) => cls._id === classId
          );
          updatedClasses[classIndex].isStarted = isStarted;
          updatedClasses[classIndex].isCompleted = isCompleted;
          return updatedClasses;
        });
        alert("تم إلغاء الحصة");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    const expirationTime = localStorage.getItem("expirationTime");
    if (expirationTime && Date.now() < expirationTime) {
      setIsButtonDisabled(true);
      const timeoutId = setTimeout(() => {
        setIsButtonDisabled(false);
        localStorage.removeItem("expirationTime");
      }, expirationTime - Date.now());
      return () => clearTimeout(timeoutId);
    } else {
      localStorage.removeItem("expirationTime");
    }
  }, [isButtonDisabled]);

  const handleSubmit = async (
    e,
    classId,
    subject,
    classDuration,
    studentID
  ) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      // Add Teacher Feedback and Update Accounting
      await addTeacherFeedback(classId, subject, studentID);
      await updateAccounting(classDuration, studentID);

      setFeedbacks((prevFeedbacks) => ({
        ...prevFeedbacks,
        [classId]: "",
      }));

      // Update Class Status
      const isStarted = false;
      const isCompleted = true;
      await axios.put(`${baseURL}/teachers/${teacherID}/classes/${classId}`, {
        isStarted,
        isCompleted,
      });

      // Update the state to reflect the added feedback
      setClasses((prevClasses) => {
        const updatedClasses = [...prevClasses];
        const classIndex = updatedClasses.findIndex(
          (cls) => cls._id === classId
        );
        updatedClasses[classIndex].isStarted = isStarted;
        updatedClasses[classIndex].isCompleted = isCompleted;
        return updatedClasses;
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    axios
      .get(`${baseURL}/teachers/${teacherID}/classes`)
      .then((response) => {
        setClasses(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  //pagination:
  const [CurrentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 5;
  const lastIndex = CurrentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = classes.slice(firstIndex, lastIndex);
  const npage = Math.ceil(classes.length / recordsPerPage);
  const numbers = [...Array(npage).keys()];
  return (
    <div className={styles.parentTable}>
      <Table className={styles.tableDirction}>
        <thead>
          <tr className={styles.head}>
            <th>
              <FontAwesomeIcon className={styles.tableIcon} icon={faBookOpen} />
              المسلسل
            </th>
            <th>
              <FontAwesomeIcon className={styles.tableIcon} icon={faUser} /> اسم
              الطالب
            </th>
            <th>
              <FontAwesomeIcon className={styles.tableIcon} icon={faClock} />
              وقت الحصة
            </th>
            <th>
              <FontAwesomeIcon className={styles.tableIcon} icon={faClock} />{" "}
              موضوع الحصة
            </th>
            <th>
              <FontAwesomeIcon className={styles.tableIcon} icon={faClock} />{" "}
              المدة
            </th>
            <th>
              <FontAwesomeIcon
                className={styles.tableIcon}
                icon={faCheckCircle}
              />
              بداية الحصة
            </th>
            <th>
              <FontAwesomeIcon
                className={styles.tableIcon}
                icon={faCheckCircle}
              />
              انتهاء الحصة
            </th>
          </tr>
        </thead>
        <tbody>
          {records.map((cls, index) => {
            let { _id, student, startTime, classDuration, isStarted, subject } =
              cls;
            return (
              <tr className="" key={_id}>
                <td>{index + 1}</td>
                <td>
                  {student.firstName} {student.lastName}
                </td>
                <td>{startTime}</td>
                <td>{subject}</td>
                <td>{classDuration}</td>
                {isStarted ? (
                  <td>
                    <a target="_blank" href={student.classZoomLink}>
                      {student.classZoomLink}
                    </a>
                  </td>
                ) : (
                  <td>
                    <button
                      className="btn btn-success"
                      onClick={() =>
                        handleStartClick(
                          _id,
                          student._id,
                          classDuration,
                          startTime
                        )
                      }
                    >
                      ابدأ الحصة
                    </button>
                  </td>
                )}
                {cls.isStarted ? (
                  <td>
                    <Form
                      onSubmit={(e) =>
                        handleSubmit(
                          e,
                          _id,
                          subject,
                          classDuration,
                          student._id
                        )
                      }
                    >
                      <Form.Control
                        type="text"
                        name={_id} // Use _id as the name, not `${_id}`
                        placeholder="تعليقك على الحصة"
                        value={feedbacks[_id] || ""} // Use feedbacks[_id] to get the correct feedback for each class
                        required
                        onChange={(e) => handleFeedbackChange(e, _id)}
                      />

                      <Button
                        variant="success"
                        type="submit"
                        className="submit-btn mt-3"
                        disabled={isLoading}
                      >
                        تسجيل
                      </Button>
                    </Form>
                    <button
                      className="btn btn-danger mt-2"
                      onClick={() => handleCancelClass(_id)}
                    >
                      إلغاء
                    </button>
                  </td>
                ) : (
                  <td>ابدأ الحصة في موعدها</td>
                )}
              </tr>
            );
          })}
        </tbody>
      </Table>
      <nav className="d-flex justify-content-center align-items-center">
        <ul className="pagination">
          <li className="page-item">
            <a href="#" className="page-link text-dark" onClick={prePage}>
              السابق
            </a>
          </li>
          {numbers.map((n, i) => {
            return (
              <li
                className={`page-item ${CurrentPage === n + 1 ? "active" : ""}`}
                key={i}
              >
                <a
                  href="#"
                  className="page-link text-dark "
                  onClick={() => changePage(n + 1)}
                >
                  {n + 1}
                </a>
              </li>
            );
          })}

          <li className="page-item">
            <a href="#" className="page-link text-dark" onClick={nextPage}>
              التالي
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
  function prePage(e) {
    if (CurrentPage !== firstIndex + 1) {
      setCurrentPage(CurrentPage - 1);
    }
  }
  function nextPage() {
    if (CurrentPage !== numbers.length) {
      setCurrentPage(CurrentPage + 1);
    }
  }
  function changePage(id) {
    setCurrentPage(id);
  }
}
