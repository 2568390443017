import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { chatAxios } from "../../config/chatAxios";

export const sendMessage = createAsyncThunk(
  "Messages/send",
  async (data, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await chatAxios.post("/message", data); //{conversationId: "Id", sender: "SenderId", text: ""}
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAllMessages = createAsyncThunk(
  "Messages/all",
  async (conversationId, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await chatAxios.get(`/message/${conversationId}`);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const initialState = {
  loading: false,
  error: false,
  success: true,
  newMessageData: null,
  allMessagesData: [],
};

const messagesReducer = createSlice({
  name: "messages",
  initialState: initialState,
  extraReducers: (builder) => {
    builder.addCase(sendMessage.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(sendMessage.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.newMessageData = action.payload;
    });
    builder.addCase(sendMessage.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
      state.newMessageData = null;
    });
    builder.addCase(getAllMessages.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getAllMessages.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.allMessagesData = action.payload;
    });
    builder.addCase(getAllMessages.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
      state.allMessagesData = null;
    });
  },
});

export default messagesReducer.reducer;
