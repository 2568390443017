import { Route, Routes, Navigate } from "react-router-dom";
import "./App.css";
import CoursesAr from "./components/Arabic/coursesAr";
import HomepageAR from "./components/Arabic/HomepageAR";
import HeaderAr from "./components/Arabic/headerAr";
import FooterAr from "./components/Arabic/footerAr";
import AboutUsAr from "./components/Arabic/aboutUsAr";
import TeacherPage from "./components/Arabic/teacherPage/TeacherPage";
import PricingCardAr from "./components/Arabic/PricingCardAr";
import Dashboard from "./components/Arabic/dashboard/dashboard";
import DashboardFreeDemos from "./components/Arabic/dashboard/freeDemos";
import DashboardStudents from "./components/Arabic/dashboard/students";
import DashboardTeachers from "./components/Arabic/dashboard/teachers";
import TeacherLogin from "./components/Arabic/teacherLogin";
import StudentLogin from "./components/Arabic/studentLogin";
import RegistrationFormAr from "./components/Arabic/newFreeDemo";
import StudentPage from "./components/Arabic/studentPage/studentPage";
import StudentClasses from "./components/Arabic/studentPage/studentClasses";
import { BrowserRouter } from "react-router-dom";
import { useAuthContext } from "./hooks/useAuthContext";
import TeacherForm from "./components/Arabic/dashboard/teacherForm";
import QuranCourseAr from "./components/Arabic/QuranCourseAr";
import IslamicCourseAr from "./components/Arabic/islamicCourse";
import ArabicCourseAr from "./components/Arabic/ArabicCourse";
import NotFound from "./components/Shared/notFound";
import Payments from "./components/Arabic/payments";
import AdminLogin from "./components/Arabic/adminLogin";
import StudentsTable from "./components/Arabic/teacherPage/StudentsTableAr";
import TeacherClasses from "./components/Arabic/teacherPage/teacherClasses";
import TeacherInformation from "./components/Arabic/teacherPage/teacherInformation";
import StudentInformation from "./components/Arabic/studentPage/studentInformation";
import Classes from "./components/Arabic/dashboard/classes/classes";
import All from "./components/Arabic/dashboard/classes/all";
import Completed from "./components/Arabic/dashboard/classes/completed";
import NotCompleted from "./components/Arabic/dashboard/classes/notCompleted";
import RightNow from "./components/Arabic/dashboard/classes/rightNow";
import TeachersAccounting from "./components/Arabic/dashboard/teachersAccounting";
import StudentsAccounting from "./components/Arabic/dashboard/studentsAccounting";
import StudentForm from "./components/Arabic/dashboard/studentForm";
import ClassForm from "./components/Arabic/dashboard/classes/classForm";
import OnlineMeeting from "./components/Arabic/onlineMeating";
import TeacherAccounting from "./components/Arabic/dashboard/teacherAccounting";
import StudentAccounting from "./components/Arabic/dashboard/studentAccounting";
import FeedbackHistory from "./components/Arabic/studentPage/feedbackHistory";
import TransactionStatus from "./components/Arabic/studentPage/transactionStatus";
import Paypal from "./components/Shared/Paypal";
import Chat from "./components/Arabic/chat";
import DashboardChat from "./components/Arabic/dashboard/chat";
import { createContext, useState } from "react";
import GoogleTranslate from "./components/Arabic/GoogleTranslate";
export const AppContext = createContext()

export default function App() {
  const { user } = useAuthContext();
  const [transOpen, setTransOpen] = useState(false);
  return (
    <AppContext.Provider value={{transOpen, setTransOpen}}> 
    <div className="App">
      <BrowserRouter>
 {transOpen ?  <div
          className={`fixed flex justify-center items-center bg-opacity-90 z-[1000] bg-[#000] w-full h-full top-0 right-0  ${
            transOpen ? "block" : "hidden"
          }`}
        >
          <div className="bg-blackGold p-12 rounded-lg relative">
            <div
              onClick={() => setTransOpen(false)}
              className="close"
            >
              X
            </div>
            <GoogleTranslate />
          </div>
        </div> : null}
        <HeaderAr />
        <Routes>
          <Route path="/home" element={<HomepageAR />} />
          {/* <Route path="/chat/:conversationId/:recieverId" element={<Chat />} /> */}
          <Route
            path="/chat/:conversationId"
            element={user ? <Chat /> : <Navigate to="/" />}
          />

          <Route
            path="/"
            element={
              !user ? (
                <HomepageAR />
              ) : user?.teacher ? (
                <Navigate to="/teacher/students" />
              ) : user?.student ? (
                <Navigate to="/student/classes" />
              ) : (
                <Navigate to="/dashboard/freeDemos" />
              )
            }
          />
          <Route path="pricing" element={<PricingCardAr />} />
          <Route path="QuranCourse" element={<QuranCourseAr />} />
          <Route path="islamicCourse" element={<IslamicCourseAr />} />
          <Route path="ArabicCourse" element={<ArabicCourseAr />} />
          <Route path="/freedemo" element={<RegistrationFormAr />} />
          <Route path="/courses" element={<CoursesAr />} />
          <Route path="/about" element={<AboutUsAr />} />
          <Route
            path="/studentLogin"
            element={
              !user?.student ? (
                <StudentLogin />
              ) : (
                <Navigate to="/student/classes" />
              )
            }
          />
          <Route
            path="/teacherLogin"
            element={
              !user?.teacher ? (
                <TeacherLogin />
              ) : (
                <Navigate to="/teacher/students" />
              )
            }
          />
          <Route
            path="/AdminLogin"
            element={
              !user?.admin ? (
                <AdminLogin />
              ) : user?.email == "eshraf@gmail.com" ? (
                <Navigate to="/rightNow" />
              ) : (
                <Navigate to="/dashboard/freeDemos" />
              )
            }
          />

          {/* <Route
            path="/AdminLogin"
            element={
              !user?.admin ? <AdminLogin /> : <Navigate to="/dashboard/freeDemos" />
            }
          /> */}

          <Route path="*" element={<NotFound />} />

          <Route
            path="/teacher"
            element={user?.teacher ? <TeacherPage /> : <Navigate to="/" />}
          >
            <Route path="students" element={<StudentsTable />} />
            <Route path="feedbackHistory/:id" element={<FeedbackHistory />} />
            <Route path="classes" element={<TeacherClasses />} />
            <Route
              path="teacherAccounting/:id"
              element={<TeacherAccounting />}
            />
            <Route path="personalInfo" element={<TeacherInformation />} />
          </Route>

          <Route
            path="/student"
            element={user?.student ? <StudentPage /> : <Navigate to="/" />}
          >
            <Route path="classes" element={<StudentClasses />} />
            <Route path="feedbackHistory/:id" element={<FeedbackHistory />} />
            <Route
              path="studentAccounting/:id"
              element={<StudentAccounting />}
            />
            <Route path="personalInfo" element={<StudentInformation />} />
            <Route path="Paypal" element={<Paypal />} />
            <Route path="transactionStatus" element={<TransactionStatus />} />
            <Route path="payments" element={<Payments />} />
            <Route path="payments/:iframURL" element={<Payments />} />
          </Route>

          {/* <Route path="transactionStatus" element={<TransactionStatus />} /> */}

          <Route
            path="/rightNow"
            element={user?.admin ? <RightNow /> : <Navigate to="/" />}
          />
          <Route
            path="/dashboard"
            element={user?.admin ? <Dashboard /> : <Navigate to="/" />}
          >
            <Route path="chat/:studentId" element={<DashboardChat />} />
            <Route path="freeDemos" element={<DashboardFreeDemos />} />
            <Route path="students" element={<DashboardStudents />} />
            <Route path="students/:id" element={<DashboardStudents />} />
            <Route path="feedbackHistory/:id" element={<FeedbackHistory />} />
            <Route path="teachers" element={<DashboardTeachers />} />
            <Route path="teacherForm" element={<TeacherForm />} />
            <Route path="teacherForm/:id" element={<TeacherForm />} />
            <Route path="studentForm/student/way" element={<StudentForm />} />
            <Route
              path="studentForm/student/:id/way/:howDidYouHearAboutUs"
              element={<StudentForm />}
            />
            <Route
              path="studentForm/student/:id/way"
              element={<StudentForm />}
            />
            <Route
              path="studentForm/student/way/:howDidYouHearAboutUs"
              element={<StudentForm />}
            />

            <Route path="classes" element={<Classes />}>
              <Route path="all" element={<All />} />
              <Route path="all/student/teacher" element={<All />} />
              <Route
                path="all/student/:studentID/teacher/:teacherID"
                element={<All />}
              />
              <Route path="all/student/:studentID/teacher" element={<All />} />
              <Route path="all/student/teacher/:teacherID" element={<All />} />
              <Route path="completed" element={<Completed />} />
              <Route path="notCompleted" element={<NotCompleted />} />
              <Route path="rightNow" element={<RightNow />} />
              <Route path="classForm/student/class" element={<ClassForm />} />
              <Route
                path="classForm/student/:studentID/class/:classID"
                element={<ClassForm />}
              />
              <Route
                path="classForm/student/class/:classID"
                element={<ClassForm />}
              />
              <Route
                path="classForm/student/:studentID/class"
                element={<ClassForm />}
              />
            </Route>

            <Route
              path="teacherAccounting/:id"
              element={<TeacherAccounting />}
            />
            <Route
              path="studentAccounting/:id"
              element={<StudentAccounting />}
            />
            <Route path="teachersAccounting" element={<TeachersAccounting />} />
            <Route path="studentsAccounting" element={<StudentsAccounting />} />
          </Route>

          <Route path="meet" element={<OnlineMeeting />}></Route>
        </Routes>
        <FooterAr />
      </BrowserRouter>
    </div>
    </AppContext.Provider>
  );
}
