import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { chatAxios } from "../../config/chatAxios";

export const getTeacherData = createAsyncThunk(
  "Teacher/getById",
  async (teacherId, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await chatAxios.get(`/teachers/${teacherId}`);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  loading: false,
  error: null,
  success: false,
  teacherData: null,
};

const teacherSlice = createSlice({
  name: "Teacher",
  initialState: initialState,
  extraReducers: (builder) => {
    builder.addCase(getTeacherData.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getTeacherData.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.teacherData = action.payload;
    });
    builder.addCase(getTeacherData.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.teacherData = null;
      state.error = action.payload;
    });
  },
});

export default teacherSlice.reducer;
