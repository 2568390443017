import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { NavLink } from "react-router-dom";
import styles from "../../css/header.module.css";
import GoogleTranslate from "./GoogleTranslate";

import GoogleTransTwo from "./GoogleTransTwo";

import { useAuthContext } from "../../hooks/useAuthContext";
import { useLogout } from "../../hooks/useLogout";

export default function HeaderAr() {
  const { user } = useAuthContext();
  const { logout } = useLogout();

  const handleClick = () => {
    logout();
  };
  return (
    <div className={styles.navAr} style={{ direction: "rtl" }}>
      <Navbar variant="dark" className={styles.nav} expand="lg">
        <Container>
   
          <Navbar.Brand>
            <img
              className={styles.img}
              src="logo eshraq final 4.png"
              alt="logo img"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse className={styles.colaps} id="basic-navbar-nav">
            <div className={`${styles.childNav} d-flex`}>
              <Nav className={`${styles.innerNav} mx-auto nav-link `}>
                <NavLink className={`${styles.links} mx-1 nav-link`} to="/home">
                  الرئيسية
                </NavLink>
                <a href="/home#courses" className={`${styles.links}  nav-link`}>
                  الدورات
                </a>
                <a
                  className={`${styles.links} mx-1 nav-link`}
                  href="/home#teachers"
                >
                  المعلمين
                </a>
              
                <NavLink
                  to="/about"
                  className={`${styles.links} mx-1 nav-link`}
                >
                  من نحن
                </NavLink>
                {/* <GoogleTransTwo /> */}
                <GoogleTranslate />
              </Nav>
              {user && (
                <div>
                  <NavLink
                    to="/teacher/students"
                    className={`${styles.links} mx-1 nav-link`}
                  >
                    {user.name ? user.name : user.email}
                  </NavLink>
                  <NavLink
                    onClick={handleClick}
                    className={`${styles.links} mx-1 nav-link text-center`}
                  >
                    تسجيل خروج
                  </NavLink>
                </div>
              )}
              {!user && (
                <NavDropdown
                  className={`${styles.links}
            ${styles.drop}  `}
                  title="تسجيل الدخول "
                  id="basic-nav-dropdown"
                >
                  <div style={{ backgroundColor: "#144d46" }}>
                    <NavLink
                      to="teacherLogin"
                      className="nav-link"
                      style={{
                        textAlign: "center",
                      }}
                    >
                      معلم
                    </NavLink>
                  </div>
                  <div style={{ backgroundColor: "#144d46" }}>
                    <NavLink
                      to="/studentLogin"
                      className="nav-link"
                      style={{
                        textAlign: "center",
                      }}
                    >
                      طالب
                    </NavLink>
                  </div>
                  <div
                    style={{
                      backgroundColor: "#144d46",
                    }}
                  >
                    <NavLink
                      to="/freedemo"
                      className="nav-link"
                      style={{
                        textAlign: "center",
                      }}
                    >
                      تسجيل حصة تجريبية
                    </NavLink>
                  </div>
                </NavDropdown>
              )}
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}
