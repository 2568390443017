import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Button, Col, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import "../../css/RegistrationFormAr.css";

const countriesCodes = [
  { السعودية: 966 },
  { الكويت: 965 },
  { قطر: 974 },
  { البحرين: 973 },
  { الإمارات: 971 },
  { تونس: 216 },
  { مصر: 20 },
  { عمان: 968 },
  { العراق: 964 },
  { اليمن: 967 },
  { سوريا: 963 },
  { ليبيا: 218 },
  { جزرالقمر: 269 },
  { الأردن: 962 },
  { الجزائر: 213 },
  // Add more timezones here
];

const hearWays = [
  { value: "وسائل التواصل الاجتماعي", label: "وسائل التواصل الاجتماعي" },
  { value: "صديق", label: "صديق" },
];

const getTimeZoneOffset = () => {
  const now = new Date();
  const timezoneOffsetMinutes = now.getTimezoneOffset();
  const timezoneOffsetHours = timezoneOffsetMinutes / 60;
  const timezoneOffset = -timezoneOffsetHours;
  return timezoneOffset;
};

export default function RegistrationFormAr() {
  let baseURL =
    "https://api.eshraqalazhar.com";
  let navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [phoneCode, setPhoneCode] = useState("");
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(null);

  const handleClose = () => {
    setShow(false);
    navigate("/home");
  };
  const handleShow = () => setShow(true);

  const showPhoneCode = (country) => {
    const countryCode = countriesCodes.find(
      (codeObj) => Object.keys(codeObj)[0] === country
    )?.[country];
    setPhoneCode(countryCode);
  };
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    age: "",
    phoneNumber: "",
    gender: "",
    country: "",
    timeZoneOffSet: getTimeZoneOffset(),
    howDidYouHearAboutUs: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "country") {
      showPhoneCode(value);
    }
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const addFreeDemo = () => {
    formData.phoneNumber = phoneCode + formData.phoneNumber;
    axios
      .post(`${baseURL}/freeDemo`, formData)
      .then((response) => {
        handleShow();
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setError(error);
      });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    addFreeDemo();
    // Submit form data
  };
  return (
    <div className="registration-form my-5">
      <h2 className="mb-5">تسجيل الحصة التجريبية </h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="form-row">
          <Form.Label>الاسم الأول</Form.Label>
          <Col sm={10}>
            <Form.Control
              className="inputDir"
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              required
            />
          </Col>
        </Form.Group>

        <Form.Group className="form-row">
          <Form.Label className="">الاسم الثاني</Form.Label>
          <Col sm={10}>
            <Form.Control
              className="inputDir"
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              required
            />
          </Col>
        </Form.Group>

        <Form.Group className="form-row">
          <Form.Label className="">الايميل</Form.Label>
          <Col sm={10}>
            <Form.Control
              className="inputDir"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </Col>
        </Form.Group>

        <Form.Group className="form-row">
          <Form.Label>السن</Form.Label>
          <Col sm={10}>
            <Form.Control
              className="inputDir"
              type="text"
              name="age"
              value={formData.age}
              onChange={handleChange}
              required
            />
          </Col>
        </Form.Group>

        <Form.Group className="form-row">
          <Form.Label>البلد</Form.Label>
          <Col sm={6} className="country">
            <Form.Control
              as="select"
              name="country"
              className="small-select"
              value={formData.country}
              onChange={handleChange}
              required
            >
              <option className="text-center" value="">
                اختر البلد
              </option>
              {countriesCodes.map((country) => (
                <option
                  key={Object.keys(country)[0]}
                  value={Object.keys(country)[0]}
                >
                  {Object.keys(country)[0]}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>

        <Form.Group className="form-row">
          <Form.Label className="">رقم الواتس آب</Form.Label>
          <Row sm={10} className="parent_whats">
            <Col sm={3} className="whats">
              <Form.Control
                className="inputDir"
                type="tel"
                name="phoneNumberCode"
                value={`${phoneCode}+`}
                disabled
              />
            </Col>
            <Col sm={8} className="whats_input">
              <Form.Control
                className="inputDir"
                type="tel"
                name="phoneNumber"
                value={formData.phoneNumber}
                placeholder="اكمل الرقم من بعد كود الدولة"
                onChange={handleChange}
                required
              />
            </Col>
          </Row>
        </Form.Group>

        <Form.Group className="form-row">
          <Form.Label>النوع</Form.Label>
          <Col sm={6} className="gender">
            <Form.Control
              as="select"
              name="gender"
              className="small-select"
              value={formData.gender}
              onChange={handleChange}
              required
            >
              <option value="">اختر النوع</option>
              <option value="male">ذكر</option>
              <option value="female">انثى</option>
            </Form.Control>
          </Col>
        </Form.Group>

        <Form.Group className="form-row">
          <Form.Label>كيف سمعت عنا ؟</Form.Label>
          <Col sm={6} className="how_find_us">
            <Form.Control
              as="select"
              name="howDidYouHearAboutUs"
              value={formData.howDidYouHearAboutUs}
              onChange={handleChange}
              required
            >
              <option value=""> من خلال:</option>
              {hearWays.map((way) => (
                <option key={way.value} value={way.value}>
                  {way.label}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>

        <div className="text-center">
          <Button
            variant="success"
            type="submit"
            className="submit-btn mt-3"
            disabled={isLoading}
          >
            تسجيل
          </Button>
          <div className="errorMsgDiv">
            {error && (
              <div className="error">
                {"حدث خطأ ما! برجاء المحاولة مرة أخرى"}
              </div>
            )}
          </div>
        </div>
      </Form>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>إشراق الأزهر</Modal.Title>
        </Modal.Header>
        <Modal.Body>سيتم التواصل معك في أقرب وقت ممكن</Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleClose}>
            حسنا
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
