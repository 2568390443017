import React from 'react'

export default function OnlineMeeting() {
  return (
    <div style={{height:"100vh"}}>
        <div id="jaas-container" />
        
    </div>
  )
}
