import React from "react";
import SliderAr from "./sliderAr";
import CoursesAr from "./coursesAr";
import Testimonials from "./TestimonialsAr";
import PricingCard from "./PricingCardAr";
import Iframe from './../Shared/Iframe';
import TeachersHomePage from "./TeachersHomePage";
function HomepageAR() {
  return (
    <div>
      <SliderAr />
      <PricingCard />
      <CoursesAr />
      <Iframe/>
      <TeachersHomePage/>
      <Testimonials />
    </div>
  );
}

export default HomepageAR;
