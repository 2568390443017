import React from "react";
import "../../css/coursedetails.css";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
export default function ArabicCourseAr() {
  let navigator = useNavigate();
  let backToHome = () => {
    navigator("/home");
  };
  return (
    <div className="QuranCourse">
      <div className="container">
        <div className="row QuranParent">
          <div className="col-md-6 col-sm-12  ">
            <img
              className="QuranImg"
              src="https://kelmetna.org/wp-content/uploads/2021/12/images-95.jpeg "
              alt="Quran"
            />
          </div>
          <div className="col-md-6 col-sm-12  ">
            {" "}
            <p className="QuranText">
              تعدّ مادة اللغة العربية في أكاديمية إشراق الأزهر من أهم المواد
              التي نقدّمها لطلابنا، فهي اللغة الأساسية لفهم وتدبر كتاب الله
              الكريم والسنة النبوية الشريفة. ومن خلال برنامجنا الشامل لتعليم
              اللغة العربية، نقدّم لطلابنا أحدث الأساليب التعليمية والمناهج
              المتخصصة التي تساعد على تحسين مهارات القراءة والكتابة والاستماع
              والتحدث باللغة العربية. ونوفر لهم مدرّسين ذوي خبرة عالية وكفاءة
              عالية في تدريس اللغة العربية وتعليمها بشكل مبسّط وسهل، مما يجعل
              تعلّم اللغة العربية في أكاديمية إشراق الأزهر تجربة ممتعة ومفيدة.
              بعد انتهاء البرنامج، يتمكّن طلابنا من الاستمتاع بفهم أفضل لمعاني
              القرآن الكريم والسنة النبوية الشريفة، وتحسين مهاراتهم في الكتابة
              والتحدث باللغة العربية، مما يمنحهم الفرصة لتحقيق نجاحات كبيرة في
              حياتهم العلمية والعملية.
            </p>
          </div>
        </div>
        <div className="row mt-3 d-flex justify-content-center align-items-center">
          <div className="col-3 d-flex justify-content-center align-items-center">
            <Button className="btn btn-success" onClick={backToHome}>
              {" "}
              العودة للصفحة الرئيسية
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
