import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "../../css/coursedetails.css";
export default function QuranCourseAr() {
  let navigator = useNavigate();
  let backToHome = () => {
    navigator("/home");
  };
  return (
    <div className="QuranCourse">
      <div className="container">
        <div className="row QuranParent">
          <div className="col-md-6 col-sm-12  ">
            <img
              className="QuranImg"
              src="https://cdn.al-ain.com/lg/images/2022/4/04/133-010850-ramadan-holy-quran-number_700x400.jpg "
              alt="Quran"
            />
          </div>
          <div className="col-md-6 col-sm-12  ">
            {" "}
            <p className="QuranText">
              تعتبر مادة القرآن الكريم في أكاديمية إشراق الأزهر من أهم المواد التي
              نقدّمها لطلابنا، فهي المصدر الأساسي لتعلم وفهم الإسلام وقيمه
              ومعانيه. ومن خلال برنامجنا الشامل لتعليم القرآن الكريم، نوفّر
              لطلابنا فرصة لتحسين مهاراتهم في تلاوة القرآن الكريم بطريقة صحيحة
              وسليمة، وفهم معاني الآيات وتطبيقها في حياتهم اليومية. ونوفّر لهم
              أيضاً دروسًا في التفسير والتدبر والتفكر في القرآن الكريم، لتحقيق
              فهمٍ أعمق وأفضل لمحتويات القرآن والأسس الأساسية للإسلام. بعد
              انتهاء البرنامج، يتمكن طلابنا من تحقيق فهمٍ أعمق وأفضل للقرآن
              الكريم، وتطبيق معانيه في حياتهم اليومية، مما يمنحهم القدرة على
              تحقيق النجاح في حياتهم العلمية والعملية والدينية. لذا، إن كنت تبحث
              عن الطريق إلى فهمٍ أفضل للإسلام وقيمه، فأكاديمية إشراق الأزهر هي المكان
              الذي تبحث عنه.
            </p>
          </div>
        </div>
        <div className="row mt-3 d-flex justify-content-center align-items-center">
          <div className="col-3 d-flex justify-content-center align-items-center">
            <Button className="btn btn-success" onClick={backToHome}>
              {" "}
              العودة للصفحة الرئيسية
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
