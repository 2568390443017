import React, { useEffect, useState } from "react";
import "../../../css/cardTeacher.css";
import { useAuthContext } from "../../../hooks/useAuthContext";
import axios from "axios";

export default function TeacherInformation() {
  let baseURL = "https://api.eshraqalazhar.com/teachers";
  const [teacher, setTeacher] = useState({});

  const { user } = useAuthContext();
  const teacherID = user.teacherObj._id;

  useEffect(() => {
    axios
      .get(`${baseURL}/${teacherID}`)
      .then((response) => {
        setTeacher(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div>
      <div className="parentCard text-center m-auto">
        <div className="mb-3">
          <img
            className="imgCard mt-2"
            src={
              teacher.gender == "female"
                ? "https://th.bing.com/th/id/OIP.fh3THt7pPdVzctV_kezkKwHaHa?w=214&h=214&c=7&r=0&o=5&dpr=1.4&pid=1.7"
                : "https://cdn4.iconfinder.com/data/icons/people-2-5/36/113-512.png "
            }
            alt="img"
          />
        </div>
        <div className="cardContent">
          <p className="d-flex flex-column justify-content-between align-items-center  mb-3 ">
            <span>الاسم </span>{" "}
            <b className="mt-2">
              {teacher.firstName} {teacher.lastName}
            </b>
          </p>
          <p className="d-flex flex-column justify-content-between align-items-center mb-3">
            العمر <b className="mt-2">{teacher.age}</b>
          </p>
          <p className="d-flex flex-column justify-content-between align-items-center mb-3">
            رقم الهاتف <b className="mt-2">{teacher.cellPhoneNumber}</b>
          </p>
          <p className="d-flex flex-column justify-content-between align-items-center mb-3">
            تاريخ الانضمام <b className="mt-2">{teacher.joiningDate}</b>
          </p>
          <p className="d-flex flex-column justify-content-between align-items-center mb-3">
            إيميل الزووم <b className="mt-2">{teacher.zoomEmailID}</b>
          </p>
          <p className="d-flex flex-column justify-content-between align-items-center mb-3">
            سعر الساعة <b className="mt-2">{teacher.ratePerHour}</b>
          </p>
        </div>
      </div>
    </div>
  );
}
