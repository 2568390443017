import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import styles from "../../../css/scheduleTable.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { useParams } from "react-router-dom";
import {
  faBookOpen,
  faBell,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import Paypal from "../../Shared/Paypal";

const currencyCodes = {
  السعودية: "SAR",
  الكويت: "KWD",
  قطر: "QAR",
  البحرين: "BHD",
  الإمارات: "AED",
  تونس: "TND",
  مصر: "EGP",
  عمان: "OMR",
  العراق: "IQD",
  اليمن: "YER",
  سوريا: "SYP",
  ليبيا: "LYD",
  جزرالقمر: "KMF",
  الأردن: "JOD",
  الجزائر: "DZD",
};

export default function StudentAccounting() {
  const { user } = useAuthContext();
  let { id } = useParams();

  let baseURL = "https://api.eshraqalazhar.com/students";

  const [student, setStudent] = useState({});
  const [accountingHistory, setAccountingHistory] = useState([]);
  const [confirmString, setConfirmString] = useState("");
  const [paypalPayment, setPaypalPayment] = useState(null);
  const [checkout, setCheckout] = useState({
    amount: 0,
    currency: "USD",
    date: "",
    studentObj: {},
  });

  useEffect(() => {
    axios
      .get(`${baseURL}/${id}`)
      .then((response) => {
        setStudent(response.data);
        setAccountingHistory(response.data.accountingHistory?.reverse());
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  useEffect(() => {
    //
  }, [checkout]);

  //
  const studentPayment = (studentId, date, bill) => {
    const currentDate = new Date();
    const [month, year] = date.split("/");
    if (
      currentDate.getFullYear() > year ||
      (currentDate.getFullYear() == year && currentDate.getMonth() + 1 > month)
    ) {
      proceedPayment();
    } else {
      alert("فاتورة الشهر لم تحسب بعد!");
    }
    function proceedPayment() {
      const promptString = prompt("اكتب 'دفع' لتأكيد عملية الدفع:");
      setConfirmString(promptString);
      if (promptString === "دفع") {
        // Perform pay operation
        const paid = true;
        function updatePayment(obj, date) {
          const updatedAccountingHistory = obj.accountingHistory
            .map((item) => {
              if (item.date === date) {
                return { ...item, paid: true };
              }
              return item;
            })
            .reverse();
          return { ...obj, accountingHistory: updatedAccountingHistory };
        }
        const studentUpdatedData = updatePayment(student, date);
        //////////////////////////////////////////////////////////
        if (user?.admin) {
          axios
            .put(`${baseURL}/${studentId}`, studentUpdatedData)
            .then((response) => {
              setStudent(studentUpdatedData);
              setAccountingHistory(response.data.accountingHistory?.reverse());
              alert("تم الدفع");
            })
            .catch((error) => {
              console.log(error);
            });
        }
        if (user?.student) {
          setCheckout({
            amount: bill.toFixed(2),
            currency: "USD",
            date: date,
            studentObj: studentUpdatedData,
          });
          setPaypalPayment(!paypalPayment);
        }
      } else {
        alert("لم يتم كتابتها بشكل صحيح");
      }
    }
  };

  let { _id, firstName, lastName, gender, currency, ratePerHour } = student;

  //pagination:
  const [CurrentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 8;
  const lastIndex = CurrentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = accountingHistory?.slice(firstIndex, lastIndex);
  const npage = Math.ceil(accountingHistory?.length / recordsPerPage);
  const numbers = [...Array(npage).keys()];
  return (
    <div className={styles.parentTable}>
      <div style={{ direction: "rtl", margin: "auto" }}>
        {user?.admin && (
          <p className={`fs-5 col-4`}>
            {gender == "female" ? "اسم الطالبة : " : "اسم الطالب : "}
            <strong>
              {firstName} {lastName}
            </strong>
          </p>
        )}
        <p className={`fs-5 col-4`}>
          سعر الساعة : <strong>{ratePerHour}</strong>
        </p>
        <p className={`fs-5 col-4`}>
          العملة : <strong>{currency}</strong>
        </p>
      </div>
      {paypalPayment ? (
        <Paypal checkout={checkout} />
      ) : (
        <>
          <Table className={styles.tableDirction}>
            <thead>
              <tr className={styles.head}>
                <th>
                  <FontAwesomeIcon
                    className={styles.tableIcon}
                    icon={faBookOpen}
                  />
                  التاريخ
                </th>
                <th>
                  <FontAwesomeIcon className={styles.tableIcon} icon={faBell} />{" "}
                  عدد الساعات
                </th>
                <th>
                  <FontAwesomeIcon className={styles.tableIcon} icon={faBell} />
                  الفاتورة
                </th>
                <th colSpan={2}>
                  <FontAwesomeIcon
                    className={styles.tableIcon}
                    icon={faQuestionCircle}
                  />
                  حالة الدفع
                </th>
              </tr>
            </thead>
            <tbody>
              {records?.map((snapShot, index) => {
                let { date, completedMinutes, paid, bill } = snapShot;

                return (
                  <tr key={index}>
                    <td>{date}</td>
                    <td>{completedMinutes / 60}</td>
                    <td>{bill}</td>
                    <td>{paid ? "تم دفع الفاتورة" : "لم يتم الدفع"}</td>
                    <td>
                      <button
                        className="btn btn-success"
                        onClick={() => studentPayment(student._id, date, bill)}
                        disabled={paid}
                      >
                        دفع
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <nav
            className={` ${styles.stBtn} d-flex justify-content-center align-items-center`}
          >
            <ul className="pagination">
              <li className="page-item">
                <a href="#" className="page-link text-dark" onClick={prePage}>
                  السابق
                </a>
              </li>
              {numbers.map((n, i) => {
                return (
                  <li
                    className={`page-item ${
                      CurrentPage === n + 1 ? "active" : ""
                    }`}
                    key={i}
                  >
                    <a
                      href="#"
                      className="page-link text-dark "
                      onClick={() => changePage(n + 1)}
                    >
                      {n + 1}
                    </a>
                  </li>
                );
              })}

              <li className="page-item">
                <a href="#" className="page-link text-dark" onClick={nextPage}>
                  التالي
                </a>
              </li>
            </ul>
          </nav>
        </>
      )}
    </div>
  );
  function prePage(e) {
    if (CurrentPage !== firstIndex + 1) {
      setCurrentPage(CurrentPage - 1);
    }
  }
  function nextPage() {
    if (CurrentPage !== numbers.length) {
      setCurrentPage(CurrentPage + 1);
    }
  }
  function changePage(id) {
    setCurrentPage(id);
  }
}
