import React from "react";
import { Container } from "react-bootstrap";

export default function NotFound() {
  return (
    <Container
      className="d-flex justify-content-center align-items-center"
      style={{ height: "60vh" }}
    >
      <div className="alert alert-danger p-5 text-center mt-3">
        <h1>الصفحة غير موجودة</h1>
      </div>
    </Container>
  );
}
