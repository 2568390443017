import React, { useEffect, useState } from "react";
import { useAuthContext } from "../../../hooks/useAuthContext";
import styles from "../../../css/scheduleTable.module.css";
import axios from "axios";
import Paypal from "../../Shared/Paypal";

export default function StudentInformation() {
  let baseURL =
    "https://api.eshraqalazhar.com/students";
  const [student, setStudent] = useState({});

  const [paypalPayment, setPaypalPayment] = useState(null);
  const [checkout, setCheckout] = useState({
    amount: 0,
    currency: "",
    date: "",
    studentObj: {},
  });
  useEffect(() => {
    //
  }, [checkout]);

  const { user } = useAuthContext();
  const studentID = user.studentObj._id;

  useEffect(() => {
    axios
      .get(`${baseURL}/${studentID}`)
      .then((response) => {
        setStudent(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <div>
      <div className="parentCard text-center m-auto mb-3">
        <div className="mb-3">
          <img
            className="imgCard mt-2"
            src={
              student.gender == "female"
                ? "https://th.bing.com/th/id/OIP.fh3THt7pPdVzctV_kezkKwHaHa?w=214&h=214&c=7&r=0&o=5&dpr=1.4&pid=1.7"
                : "https://cdn4.iconfinder.com/data/icons/people-2-5/36/113-512.png "
            }
            alt="img"
          />
        </div>
        <div className="cardContent">
          <p className="d-flex flex-column justify-content-between align-items-center  mb-3 ">
            <span>الاسم </span>{" "}
            <b className="mt-2">
              {student.firstName} {student.lastName}
            </b>
          </p>
          <p className="d-flex flex-column justify-content-between align-items-center mb-3">
            العمر <b className="mt-2">{student.age}</b>
          </p>
          <p className="d-flex flex-column justify-content-between align-items-center mb-3">
            رقم الهاتف <b className="mt-2">{student.cellPhoneNumber}</b>
          </p>
          <p className="d-flex flex-column justify-content-between align-items-center mb-3">
            تاريخ الانضمام <b className="mt-2">{student.joiningDate}</b>
          </p>
          <p
            className={`${styles.zoomLink} d-flex flex-column justify-content-between align-items-center mb-3`}
          >
            لينك حصة الزووم <b className="mt-2">{student.classZoomLink}</b>
          </p>
          <p className="d-flex flex-column justify-content-between align-items-center mb-3">
            خطة الاشتراك <b className="mt-2">{student.planFees}</b>
            <b className="mt-2">
              <button
                className="btn btn-success"
                onClick={() => {
                  setCheckout({
                    amount: student.planFees.toFixed(2),
                    currency: "USD",
                    date: "",
                    studentObj: student,
                  });
                  setPaypalPayment(!paypalPayment);
                }}
              >
                دفع
              </button>
            </b>
          </p>
        </div>
      </div>
      {paypalPayment && <Paypal checkout={checkout} />}
    </div>
  );
}
