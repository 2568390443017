import React, { useState, useEffect } from "react";
import { Button, Table } from "react-bootstrap";
import styles from "../../../css/scheduleTable.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBookOpen,
  faUser,
  faBell,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

export default function StudentsAccounting() {
  let baseURL = "https://api.eshraqalazhar.com/students";
  const [students, setStudents] = useState([]);
  useEffect(() => {
    axios
      .get(`${baseURL}`)
      .then((response) => {
        setStudents(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const studentPaying = (studentId) => {
    const paid = true;

    axios
      .put(`${baseURL}/${studentId}`, {
        paid,
      })
      .then((response) => {
        setStudents((studentOldData) => {
          const studentUpdatedData = [...studentOldData];
          const studentIndex = studentUpdatedData.findIndex(
            (student) => student._id === studentId
          );
          studentUpdatedData[studentIndex].paid = paid;
          return studentUpdatedData;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const resetAccounts = () => {
    axios
      .put(`${baseURL}/accounts/reset`)
      .then((response) => {
        setStudents(response.data.students);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div className={styles.parentTable}>
      <Button
        className="btn btn-danger mb-3 align-items-center"
        onClick={resetAccounts}
      >
        إعادة ضبط
      </Button>
      <Table className={styles.tableDirction}>
        <thead>
          <tr className={styles.head}>
            <th>
              <FontAwesomeIcon className={styles.tableIcon} icon={faBookOpen} />{" "}
              المسلسل
            </th>
            <th>
              <FontAwesomeIcon className={styles.tableIcon} icon={faUser} /> اسم
              الطالب
            </th>
            <th>
              <FontAwesomeIcon className={styles.tableIcon} icon={faBell} />{" "}
              المدفوعات
            </th>
            <th>
              <FontAwesomeIcon
                className={styles.tableIcon}
                icon={faQuestionCircle}
              />{" "}
              حالة الدفع
            </th>
            <th>
              <FontAwesomeIcon
                className={styles.tableIcon}
                icon={faQuestionCircle}
              />{" "}
              من خلال
            </th>
          </tr>
        </thead>
        <tbody>
          {students.map((student, index) => {
            let { _id, firstName, lastName, planFees, paid } = student;

            return (
              <tr className="" key={_id}>
                <td>{index + 1}</td>
                <td>
                  {firstName} {lastName}
                </td>
                <td>{planFees}</td>
                <td>{paid ? "تم الدفع" : "لم يتم الدفع"}</td>
                <td>
                  <button
                    className="btn btn-success"
                    onClick={() => studentPaying(student._id)}
                    disabled={paid}
                  >
                    دفع
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}
