import React from "react";
import styles from "../../../css/scheduleTable.module.css";
import { NavLink, Outlet } from "react-router-dom";
import imglogo from "../../../assets/images/logo eshraq final 3.png";
export default function Dashboard() {
  var today = new Date();
  var days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  var d = new Date();
  var dayName = days[d.getDay()];
  var dd = today.getDate();
  var mm = today.getMonth() + 1; //January is 0!
  var yyyy = today.getFullYear();
  today = `(${dayName}) ${dd} / ${mm} / ${yyyy}`;
  return (
    <div className={styles.parentTable}>
      <div className="container">
          <div className={`${styles.logo} ${styles.dirction} col-12 `}>
            <img className={styles.img} src={imglogo} alt="logo" />
          </div>
        <div className={`${styles.headerOfstudentTable} align-items-center`}>
          <div className={`${styles.text} mx-3 col-5`}>
             <h4 >
              <b>مرحبا شيخ :</b> <strong>عبد القيوم</strong>
            </h4>
          </div>
           
          <div className={`${styles.date} col-5`}>
            <b> {today}</b>
          </div>
        </div>
      </div>
      <div className="container">
        <div className={styles.navStudent}>
          <NavLink
            className={`${styles.linkOfStudent} nav-link`}
            to="freeDemos"
          >
            الحصص التجريبية
          </NavLink>
          <NavLink className={`${styles.linkOfStudent} nav-link`} to="students">
            الطلاب
          </NavLink>
          <NavLink className={`${styles.linkOfStudent} nav-link`} to="teachers">
            المعلمين
          </NavLink>
          <NavLink
            className={`${styles.linkOfStudent} nav-link`}
            to="classes/all"
          >
            الحصص
          </NavLink>
        </div>
        <div>
          <Outlet />
        </div>
      </div>
    </div>
  );
}
