import React from "react";
import "../../css/coursedetails.css";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
export default function IslamicCourseAr() {
  let navigator = useNavigate();
  let backToHome = () => {
    navigator("/home");
  };
  return (
    <div className="QuranCourse">
      <div className="container">
        <div className="row QuranParent">
          <div className="col-md-6 col-sm-12  ">
            <img
              className="QuranImg"
              src="https://www.sayidaty.net/sites/default/files/styles/600x380/public/2020/04/20/6643356-1051183105.jpg "
              alt="Quran"
            />
          </div>
          <div className="col-md-6 col-sm-12  ">
            {" "}
            <p className="QuranText">
              تعتبر مادة الدراسات الإسلامية في أكاديمية إشراق الأزهر  من أهم المواد التي
              نقدّمها لطلابنا، فهي توفّر لهم فرصة لفهم الإسلام بشكل شامل، من
              خلال دراسة تاريخه وأصوله وقيمه وتطبيقاته في الحياة اليومية. ومن
              خلال برنامجنا الشامل لتعليم الدراسات الإسلامية، نوفّر لطلابنا
              الفرصة لتطوير مهاراتهم في تحليل وتفسير النصوص الإسلامية، وفهم
              مفاهيم الإسلام وقيمه وتطبيقاتها في الحياة العملية والاجتماعية. كما
              يتضمن البرنامج أيضًا دروسًا في التعامل مع التحديات الحديثة التي
              تواجه المجتمع الإسلامي في العالم الحديث، والتي تشمل التحديات
              الاجتماعية والاقتصادية والسياسية والثقافية. بعد انتهاء البرنامج،
              يتمكن طلابنا من فهم الإسلام بشكل شامل ومن تطبيق قيمه في حياتهم
              اليومية، مما يمنحهم القدرة على التعامل مع التحديات الحديثة التي
              يواجهها المجتمع الإسلامي بثقة ونجاح. لذا، إن كنت ترغب في فهم
              الإسلام بشكل شامل والحصول على الأدوات اللازمة لتحقيق النجاح في
              حياتك العلمية والعملية والدينية، فأكاديمية إشراق الأزهر هي المكان الذي
              تبحث عنه.
            </p>
          </div>
        </div>
        <div className="row mt-3 d-flex justify-content-center align-items-center">
          <div className="col-3 d-flex justify-content-center align-items-center">
            <Button className="btn btn-success" onClick={backToHome}>
              {" "}
              العودة للصفحة الرئيسية
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
