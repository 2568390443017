import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import styles from "../../../css/scheduleTable.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { useAuthContext } from "../../../hooks/useAuthContext";
import {
  faBookOpen,
  faClock,
  faLink,
  faBell,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { useDispatch } from "react-redux";
import { getTeacherData, createConversation } from "../../../redux";
import { useNavigate } from "react-router-dom";

const countries = {
  السعودية: 1,
  الكويت: 1,
  قطر: 1,
  البحرين: 1,
  الإمارات: 2,
  تونس: -1,
  مصر: 0,
  عمان: 2,
  العراق: 1,
  اليمن: 1,
  سوريا: 0,
  ليبيا: 0,
  جزرالقمر: 1,
  الأردن: 1,
  الجزائر: -1,
};

const countriesSummerTiming = {
  السعودية: 0,
  الكويت: 0,
  قطر: 0,
  البحرين: 0,
  الإمارات: 1,
  تونس: -2,
  مصر: 0,
  عمان: 1,
  العراق: 0,
  اليمن: 0,
  سوريا: 0,
  ليبيا: -1,
  جزرالقمر: 0,
  الأردن: 0,
  الجزائر: -2,
};

export default function StudentClasses() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useAuthContext();
  const teacherId = user?.studentObj?.teacher;
  const studentId = user.student;
  let baseURL = "https://api.eshraqalazhar.com";
  const [classes, setClasses] = useState([]);
  const [teacherData, setTeacherData] = useState(null);

  function adjustTime(timeStr, hours) {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    const [dayOfWeek, time] = timeStr.split(" ");
    const [hoursStr, minutesStr] = time.split(":");

    let newHours = parseInt(hoursStr, 10) + hours;
    let newDayOfWeek = dayOfWeek;

    if (newHours >= 24) {
      newHours -= 24;
      const dayIndex = (daysOfWeek.indexOf(dayOfWeek) + 1) % 7;
      newDayOfWeek = daysOfWeek[dayIndex];
    } else if (newHours < 0) {
      newHours += 24;
      const dayIndex = (daysOfWeek.indexOf(dayOfWeek) - 1 + 7) % 7;
      newDayOfWeek = daysOfWeek[dayIndex];
    }

    const newTimeStr = `${newDayOfWeek} ${newHours
      .toString()
      .padStart(2, "0")}:${minutesStr}`;
    return newTimeStr;
  }

  useEffect(() => {
    axios
      .get(`${baseURL}/classes/students/${studentId}`)
      .then((response) => {
        setClasses(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    // Get Teacher data
    const getTeacher = async () => {
      try {
        const res = await dispatch(getTeacherData(teacherId)).unwrap();
        setTeacherData(res);
      } catch (error) {
        console.log(error);
      }
    };
    getTeacher();
  }, []);

  //pagination:
  const [CurrentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 5;
  const lastIndex = CurrentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = classes.slice(firstIndex, lastIndex);
  const npage = Math.ceil(classes.length / recordsPerPage);
  const numbers = [...Array(npage).keys()];

  //////////////// Chat
  const onStartConversation = async () => {
    try {
      const data = { senderId: studentId, recieverId: teacherId };
      const res = await dispatch(createConversation(data)).unwrap();
      if (res.success) {
        navigate(`/chat/${res.data._id}`, {
          state: {
            recieverId: teacherData._id,
            recieverFirstName: teacherData.firstName,
            recieverlastName: teacherData.lastName,
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className={styles.parentTable}>
      <div style={{ direction: "rtl", margin: "auto" }}>
        <p className={` fs-6 col-4`}>
          لينك الحصة :{" "}
          <a
            className={`${styles.zoomLink}`}
            href={`${user.studentObj.classZoomLink} `}
            target="_blank"
          >
            {user.studentObj.classZoomLink}
          </a>
        </p>
        <p className={` fs-5 col-4`}>
          راسل المعلم :{" "}
          <button
            className={"chat-icon"}
            onClick={onStartConversation} //// Route
          >
            <strong>
              <FontAwesomeIcon icon={faEnvelope} />
            </strong>
          </button>
        </p>
      </div>
      <Table className={styles.tableDirction}>
        <thead>
          <tr className={styles.head}>
            <th>
              <FontAwesomeIcon className={styles.tableIcon} icon={faBookOpen} />
              المسلسل
            </th>
            <th>
              <FontAwesomeIcon className={styles.tableIcon} icon={faClock} />
              بداية الحصة
            </th>
            <th>
              <FontAwesomeIcon className={styles.tableIcon} icon={faBell} />
              موضوع الحصة
            </th>
            <th>
              <FontAwesomeIcon className={styles.tableIcon} icon={faClock} />
              المدة
            </th>
            <th>
              <FontAwesomeIcon
                className={styles.tableIcon}
                icon={faCheckCircle}
              />
              بدأت الحصة
            </th>
          </tr>
        </thead>
        <tbody>
          {records.map((cls, index) => {
            let { startTime, subject, classDuration, isStarted } = cls;
            const localStartTime = adjustTime(
              startTime,
              countriesSummerTiming[user.studentObj.country]
            );

            return (
              <tr key={cls._id}>
                <td>{index + 1}</td>
                <td>{localStartTime}</td>
                <td>{subject}</td>
                <td>{classDuration}</td>
                <td>{isStarted ? "المعلم بانتظارك" : "لا"}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <nav className="d-flex justify-content-center align-items-center">
        <ul className="pagination">
          <li className="page-item">
            <a className="page-link text-dark" onClick={prePage}>
              السابق
            </a>
          </li>
          {numbers.map((n, i) => {
            return (
              <li
                className={`page-item ${CurrentPage === n + 1 ? "active" : ""}`}
                key={i}
              >
                <a
                  className="page-link text-dark "
                  onClick={() => changePage(n + 1)}
                >
                  {n + 1}
                </a>
              </li>
            );
          })}

          <li className="page-item">
            <a className="page-link text-dark" onClick={nextPage}>
              التالي
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
  function prePage(e) {
    if (CurrentPage !== firstIndex + 1) {
      setCurrentPage(CurrentPage - 1);
    }
  }
  function nextPage() {
    if (CurrentPage !== numbers.length) {
      setCurrentPage(CurrentPage + 1);
    }
  }
  function changePage(id) {
    setCurrentPage(id);
  }
}
