import React, { useEffect, useState } from "react";
import axios from "axios";
import { Form, Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

const initialFormState = {
  student: "",
  teacher: "",
  subject: "",
  startTime: "",
  classDuration: "",
};

export default function ClassForm() {
  let baseURL =
    "https://api.eshraqalazhar.com";

  const [teacher, setTeacher] = useState({});
  const [student, setStudent] = useState({});
  const [_class, setClass] = useState({});
  const [wClasses, setWClasses] = useState([]);

  const [formState, setFormState] = useState(initialFormState);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(null);


  const [error, setError] = useState(null);

  let { studentID, classID } = useParams();
  let navigate = useNavigate();

  const handleInputChange = (event, index) => {
    const { name, value } = event.target;
    setFormState({ ...formState, [name]: value });
  };

  const validateForm = () => {
    const errors = {};
    if (!formState.student) {
      errors.student = "أدخل اسم الطالب";
    }
    if (!formState.teacher) {
      errors.teacher = "أدخل اسم المعلم";
    }
    if (!formState.subject) {
      errors.subject = "أدخل اسم المادة";
    }
    if (!formState.startTime) {
      errors.startTime = "أدخل بداية الوقت";
    }
    if (!formState.classDuration) {
      errors.classDuration = "أدخل مدة الحصة";
    }

    return errors;
  };

  const editClass = async () => {
    try {
      await axios.put(`${baseURL}/classes/${classID}`, formState);
      setFormState(initialFormState);
      setIsLoading(false);
      alert("تم تعديل الحصة بنجاح!");
      navigate(`/dashboard/students`);
    } catch (error) {
      console.error(error);
      setError(error);
    }
  };

  const addClass = async () => {
    try {
      await axios.post(`${baseURL}/classes`, formState);
      setFormState(initialFormState);
      setIsLoading(false);
      alert("تمت إضافة الحصة بنجاح!");
      navigate(`/dashboard/students`);
    } catch (error) {
      console.error(error);
      setError(error);
    }
  };

  const handleSubmit = async (event) => {
    setIsLoading(true);
    event.preventDefault();
    const errors = validateForm();
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      if (studentID && classID) {
        editClass();
      } else {
        addClass();
      }
    }
  };

  useEffect(() => {
    if (studentID && classID) {
      axios
        .get(`${baseURL}/students/${studentID}`)
        .then((response) => {
          setTeacher(response.data.teacher);
          setStudent(response.data);
          setWClasses(response.data.weeklyClasses);
        })
        .catch((error) => {
          console.log(error);
        });
      //
    }
  }, []);

  useEffect(() => {
    if (!classID && studentID) {
      axios
        .get(`${baseURL}/students/${studentID}`)
        .then((response) => {
          setTeacher(response.data.teacher);
          setStudent(response.data);
          setWClasses(response.data.weeklyClasses);
          setFormState({
            ...formState,
            student: response.data?._id,
            teacher: response.data.teacher?._id,
            subject: "",
            startTime: "",
            classDuration: "",
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  useEffect(() => {
    if (studentID && classID) {
      axios
        .get(`${baseURL}/classes/${classID}`)
        .then((response) => {
          setClass(response.data.data);
          setFormState({
            ...formState,
            student: student?._id,
            teacher: teacher?._id,
            subject: response.data.data.subject,
            startTime: response.data.data.startTime,
            classDuration: response.data.data.classDuration,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [student, teacher]);

  return (
    <div style={{ direction: "rtl", width: "50%", margin: "auto" }}>
      <Form
        onSubmit={handleSubmit}
        className="justify-content-center align-items-center"
      >
        <Form.Group controlId="student">
          <Form.Label>
            <strong> الطالب :</strong> {student?.firstName} {student?.lastName}
          </Form.Label>
        </Form.Group>

        <Form.Group controlId="teacher">
          <Form.Label>
            <strong>المعلم :</strong> {teacher?.firstName} {teacher?.lastName}
          </Form.Label>
        </Form.Group>

        <Form.Group controlId="subject">
          <Form.Label>موضوع الحصة</Form.Label>
          <Form.Control
            type="text"
            name="subject"
            defaultValue={_class?.subject}
            onChange={handleInputChange}
            isInvalid={errors.subject}
          />
          <Form.Control.Feedback type="invalid">
            {errors.subject}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="startTime">
          <Form.Label>وقت الحصة</Form.Label>
          <Form.Control
            as="select"
            name="startTime"
            value={formState.startTime}
            onChange={handleInputChange}
            isInvalid={errors.startTime}
          >
            <option value="">-- اختر الوقت --</option>
            {wClasses.map((weeklyClass, index) => (
              <option
                key={index}
                value={weeklyClass.dayOfWeek + " " + weeklyClass.startTime}
              >
                {weeklyClass.dayOfWeek} {weeklyClass.startTime}
              </option>
            ))}
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            {errors.startTime}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="classDuration">
          <Form.Label>مدة الحصة</Form.Label>
          <Form.Control
            as="select"
            name="classDuration"
            value={formState.classDuration}
            onChange={handleInputChange}
            isInvalid={errors.classDuration}
          >
            <option value="">-- حدد المدة --</option>
            <option value="30"> ٣٠ دقيقة </option>
            <option value="40"> ٤٠ دقيقة </option>
            <option value="45"> ٤٥ دقيقة </option>
            <option value="60"> ٦٠ دقيقة </option>
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            {errors.classDuration}
          </Form.Control.Feedback>
        </Form.Group>

        <div className="w-100 justify-content-center text-center mt-4">
          <div className="errorMsgDiv">
            {error && (
              <div className="error">
                {"حدث خطأ ما! برجاء المحاولة مرة أخرى"}
              </div>
            )}
          </div>
          <Button type="submit" variant="success" disabled={isLoading}>
            {classID ? "تعديل الحصة" : "إضافة حصة"}
          </Button>
        </div>
      </Form>
    </div>
  );
}
