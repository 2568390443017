import React from "react";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import styles from "../../css/availableCourses.module.css";

export default function CoursesAr() {
  return (
    <div id="courses"className={styles.wrapper} style={{ direction: "rtl" }}>
      <Container>
        <h2>الكورسات المتاحة</h2>
        <Row
          xs={1}
          md={3}
          className={`${styles.courseCards} justify-content-center mt-lg-4`}
        >
          <Col className="mb-5 col-lg-4 col-md-6 col-sm-12">
            <div className={styles.card}>
              <div className={styles.cardImgWrapper}>
                <Image
                style={{ height: "262px", width: "100%" }}
                  src={`https://cdn.al-ain.com/lg/images/2022/4/04/133-010850-ramadan-holy-quran-number_700x400.jpg`}
                  fluid
                />
                <div className={styles.cardOverlay}>
                  <Link to="/QuranCourse">
                    <Button variant="secondary">شاهد المزيد</Button>
                  </Link>
                </div>
              </div>
              <div className={styles.cardContent}>
                <h5 className="text-center">
                  <strong>القرآن الكريم</strong>
                </h5>
                <ul className="text-muted fw-bold">
                  <li>تحفيظ</li>
                  <li>تجويد</li>
                  <li>تفسير</li>
                </ul>
              </div>
            </div>
          </Col>
          <Col className="mb-5 col-lg-4 col-md-6 col-sm-12">
            <div className={styles.card}>
              <div className={styles.cardImgWrapper}>
                <Image
                style={{ height: "262px", width: "100%" }}
                  src={`https://www.sayidaty.net/sites/default/files/styles/600x380/public/2020/04/20/6643356-1051183105.jpg`}
                  fluid
                />
                <div className={styles.cardOverlay}>
                  <Link to="/islamicCourse">
                    <Button variant="secondary">شاهد المزيد</Button>
                  </Link>
                </div>
              </div>
              <div className={styles.cardContent}>
                <h5 className="text-center">
                  <strong>الدراسات الاسلامية</strong>
                </h5>
                <ul className="text-muted fw-bold">
                  <li>فقه</li>
                  <li>عقيدة</li>
                  <li>السيرة النبوية</li>
                </ul>
              </div>
            </div>
          </Col>
          <Col className="mb-5 col-lg-4 col-md-6 col-sm-12">
            <div className={styles.card}>
              <div className={styles.cardImgWrapper}>
                <Image
                style={{ height: "262px", width: "100%" }}
                  src={`https://kelmetna.org/wp-content/uploads/2021/12/images-95.jpeg`}
                  fluid
                />
                <div className={styles.cardOverlay}>
                  <Link to="/ArabicCourse">
                    <Button variant="secondary">شاهد المزيد</Button>
                  </Link>
                </div>
              </div>
              <div className={styles.cardContent}>
                <h5 className="text-center">
                  <strong>اللغة العربية</strong>
                </h5>
                <ul className="text-muted fw-bold">
                  <li>تعليم القراءة</li>
                  <li>تعليم اللغة العربية الفصحى</li>
                  <li>الصرف والنحو</li>
                </ul>{" "}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
