import React from "react";
import { useNavigate } from "react-router-dom";
import { Card, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import "../../css/PricingCardAr.css";
const PricingCardAr = ({ plan }) => {
    let navigator = useNavigate();
    let redistration = () => {
      navigator("/freeDemo");
    };
  return (
    <div className="container ">
    <div className="row parentPricing w-100">
      <h2 className="text-center my-4 offers" > العروض </h2>
      <Card
        className="col-lg-3 col-md-6 col-sm-16 pricing-card shadow justify-content-center align-items-center"
        dir="rtl"
      >
        <Card.Header
          className="pricing-header w-100 mt-1 border-info border-end"
          style={{ backgroundColor: " #144d46" }}
        >
          <h3>٨ حصص / شهر</h3>
        </Card.Header>
        <Card.Body>
          <div className="pricing-price">
            <span className="period">يومان / أسبوع</span>
          </div>
          <ul className="pricing-features">
            <li>
              <FontAwesomeIcon icon={faCheck} className="check-icon" /> يومان /
              أسبوع
            </li>
            <li>
              <FontAwesomeIcon icon={faCheck} className="check-icon" />
              ٤٠ دقيقة /حصة
            </li>
            <li className="text-decoration-line-through">
              <FontAwesomeIcon icon={faCheck} className="check-icon" />
              خصم ١٠ %
            </li>
          </ul>
          <Button
            variant="primary"
            className="buy-button"
            onClick={redistration}
          >
            سجل الان
          </Button>
        </Card.Body>
      </Card>

      <Card
        className="col-lg-3 col-md-6 col-sm-16 pricing-card shadow justify-content-center align-items-center"
        dir="rtl"
      >
        <Card.Header
          className="pricing-header w-100 mt-1 border-info border-end"
          style={{ backgroundColor: " #144d46" }}
        >
          <h3>١٢ حصة / شهر</h3>
        </Card.Header>
        <Card.Body>
          <div className="pricing-price">
            <span className="period">٣ أيام / أسبوع</span>
          </div>
          <ul className="pricing-features">
            <li>
              <FontAwesomeIcon icon={faCheck} className="check-icon" />٣ أيام /
              أسبوع
            </li>
            <li>
              <FontAwesomeIcon icon={faCheck} className="check-icon" />
              ٤٠ دقيقة / حصة
            </li>
            <li className="text-decoration-line-through">
              <FontAwesomeIcon icon={faCheck} className="check-icon" />
              خصم ٣٠ %
            </li>
          </ul>
          <Button
            variant="primary"
            className="buy-button"
            onClick={redistration}
          >
            سجل الان
          </Button>
        </Card.Body>
      </Card>

      <Card
        className="col-lg-3 col-md-6 col-sm-16 pricing-card shadow justify-content-center align-items-center"
        dir="rtl"
      >
        <Card.Header
          className="pricing-header w-100 mt-1 border-info border-end"
          style={{ backgroundColor: " #144d46" }}
        >
          <h3>١٦ حصة / شهر</h3>
        </Card.Header>
        <Card.Body>
          <div className="pricing-price">
            <span className="period">٤ أيام / أسبوع</span>
          </div>
          <ul className="pricing-features">
            <li>
              <FontAwesomeIcon icon={faCheck} className="check-icon" />٤ أيام / أسبوع
            </li>
            <li>
              <FontAwesomeIcon icon={faCheck} className="check-icon" />
              ٤٠ دقيقة / حصة
            </li>
            <li className="text-decoration-line-through">
              <FontAwesomeIcon icon={faCheck} className="check-icon" />
              خصم ٤٠ %
            </li>
          </ul>
          <Button
            variant="primary"
            className="buy-button"
            onClick={redistration}
          >
            سجل الان
          </Button>
        </Card.Body>
      </Card>
    </div>

    </div>
  );
};

export default PricingCardAr;



