import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Iframe = ({ videoId }) => {
  return (
    <div style={{ padding: "20px 0", backgroundColor: "#f7f7f7" }}>
      <Container>
        <Row>
          <Col>
            <iframe
              width="100%"
              height="500"
              src="https://www.youtube.com/embed/IysZ4qUhVBE"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Iframe;
