import React from "react";
import styles from "../../../../css/scheduleTable.module.css";
import { NavLink, Outlet } from "react-router-dom";

export default function Classes() {
  return (
    <div className={styles.parentTable}>
      <div className="container">
        <div className={styles.navStudent}>
          <NavLink className={`${styles.linkOfStudent} nav-link`} to="all">
            كل الحصص
          </NavLink>
          <NavLink
            className={`${styles.linkOfStudent} nav-link`}
            to="notCompleted"
          >
            الحصص القادمة
          </NavLink>
          <NavLink className={`${styles.linkOfStudent} nav-link`} to="rightNow">
            الحصص الحالية
          </NavLink>
          <NavLink
            className={`${styles.linkOfStudent} nav-link`}
            to="completed"
          >
            الحصص المنتهية
          </NavLink>
        </div>
        <div>
          <Outlet />
        </div>
      </div>
    </div>
  );
}
