import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import styles from "../../../css/scheduleTable.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { useParams } from "react-router-dom";

import {
  faBookOpen,
  faBell,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

export default function TeacherAccounting() {
  const { user } = useAuthContext();
  let { id } = useParams();
  let baseURL = "https://api.eshraqalazhar.com/teachers";

  const [teacher, setTeacher] = useState({});
  const [earningHistory, setEarningHistory] = useState([]);

  const [confirmString, setConfirmString] = useState("");

  useEffect(() => {
    axios
      .get(`${baseURL}/${id}`)
      .then((response) => {
        setTeacher(response.data);
        setEarningHistory(response.data.earningHistory?.reverse());
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  const proceedPayment = (teacherId, date) => {
    const promptString = prompt("اكتب 'دفع' لتأكيد عملية الدفع:");
    setConfirmString(promptString);
    if (promptString === "دفع") {
      // Perform pay operation
      const gotPaid = true;

      function updateGotPaid(obj, date) {
        const updatedEarningHistory = obj.earningHistory
          .map((item) => {
            if (item.date === date) {
              return { ...item, gotPaid: true };
            }
            return item;
          })
          .reverse();

        return { ...obj, earningHistory: updatedEarningHistory };
      }

      const teacherUpdatedData = updateGotPaid(teacher, date);

      axios
        .put(`${baseURL}/${teacherId}`, teacherUpdatedData)
        .then((response) => {
          setTeacher(teacherUpdatedData);
          setEarningHistory(response.data.earningHistory?.reverse());
          alert("تم الدفع");
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      alert("لم يتم كتابتها بشكل صحيح");
    }
  };

  const teacherGotPaid = (teacherId, date) => {
    const currentDate = new Date();
    const [month, year] = date.split("/");
    if (
      currentDate.getFullYear() > year ||
      (currentDate.getFullYear() == year && currentDate.getMonth() + 1 > month)
    ) {
      proceedPayment(teacherId, date);
    } else {
      alert("فاتورة الشهر لم تحسب بعد!");
    }
  };

  let { _id, firstName, lastName, gender, ratePerHour } = teacher;

  //pagination:
  const [CurrentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 6;
  const lastIndex = CurrentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = earningHistory?.slice(firstIndex, lastIndex);
  const npage = Math.ceil(earningHistory?.length / recordsPerPage);
  const numbers = [...Array(npage).keys()];
  return (
    <div className={styles.parentTable}>
      <div style={{ direction: "rtl", margin: "auto" }}>
        {user?.admin && (
          <p className={`fs-5 col-4`}>
            {gender == "female" ? "اسم المعلمة : " : "اسم المعلم : "}
            <strong>
              {firstName} {lastName}
            </strong>
          </p>
        )}
        <p className={`fs-5 col-4`}>
          {"سعر الساعة :"} <strong>{ratePerHour}</strong>
        </p>
      </div>
      <Table className={styles.tableDirction}>
        <thead>
          <tr className={styles.head}>
            <th>
              <FontAwesomeIcon className={styles.tableIcon} icon={faBookOpen} />
              التاريخ
            </th>
            <th>
              <FontAwesomeIcon className={styles.tableIcon} icon={faBell} />
              ساعات العمل
            </th>
            <th>
              <FontAwesomeIcon className={styles.tableIcon} icon={faBell} />
              المرتب
            </th>
            <th colSpan={user?.admin ? 2 : 1}>
              <FontAwesomeIcon className={styles.tableIcon} icon={faBell} />
              حالة المرتب
            </th>
          </tr>
        </thead>
        <tbody>
          {records?.map((snapShot, index) => {
            let { date, completedMinutes, gotPaid, salary } = snapShot;

            return (
              <tr className="" key={index}>
                <td>{date}</td>
                <td>{completedMinutes / 60}</td>
                <td>{salary}</td>
                <td>{gotPaid ? "تم استلام المرتب" : "لم يتم الاستلام"}</td>
                {user?.admin && (
                  <td>
                    <button
                      className="btn btn-success"
                      onClick={() => teacherGotPaid(teacher._id, date)}
                      disabled={gotPaid}
                    >
                      دفع
                    </button>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </Table>
      <nav
        className={` ${styles.stBtn} d-flex justify-content-center align-items-center`}
      >
        <ul className="pagination">
          <li className="page-item">
            <a href="#" className="page-link text-dark" onClick={prePage}>
              السابق
            </a>
          </li>
          {numbers.map((n, i) => {
            return (
              <li
                className={`page-item ${CurrentPage === n + 1 ? "active" : ""}`}
                key={i}
              >
                <a
                  href="#"
                  className="page-link text-dark "
                  onClick={() => changePage(n + 1)}
                >
                  {n + 1}
                </a>
              </li>
            );
          })}

          <li className="page-item">
            <a href="#" className="page-link text-dark" onClick={nextPage}>
              التالي
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
  function prePage(e) {
    if (CurrentPage !== firstIndex + 1) {
      setCurrentPage(CurrentPage - 1);
    }
  }
  function nextPage() {
    if (CurrentPage !== numbers.length) {
      setCurrentPage(CurrentPage + 1);
    }
  }
  function changePage(id) {
    setCurrentPage(id);
  }
}
