import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function TransactionStatus({ status }) {
  const [isSuccess, setIsSuccess] = useState(false);
  const [countdown, setCountdown] = useState(10); // set the initial countdown value
  const navigate = useNavigate(); // get the navigate function

  useEffect(() => {
    // const searchParams = new URLSearchParams(window.location.search);
    // const successParam = searchParams.get("success");
    setIsSuccess(status === true);

    // start the countdown timer
    const timer = setInterval(() => {
      setCountdown((countdown) => countdown - 1);
    }, 1000);

    // cleanup function to clear the interval when the component unmounts
    return () => clearInterval(timer);
  }, []);

  // navigate to the target component when the countdown reaches zero
  useEffect(() => {
    if (countdown === 0) {
      navigate("/student/classes");
    }
  }, [countdown, navigate]);

  return (
    <Container>
      <div
        className={`alert alert-${
          isSuccess ? `success` : `danger`
        } p-5 text-center mt-3`}
      >
        {isSuccess ? <h1>تمت العملية بنجاح</h1> : <h1>حدث خطأ ما! برجاء المحاولة مرة أخرى</h1>}
        <p>سيتم التوجيه إلى الصفحة التالية في غضون {countdown} ثانية...</p>
      </div>
    </Container>
  );
}
