import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import styles from "../../../css/scheduleTable.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { useParams } from "react-router-dom";
import { faBookOpen, faBell } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

export default function FeedbackHistory() {
  const { user } = useAuthContext();
  let { id } = useParams();

  let baseURL = "https://api.eshraqalazhar.com/students";
  const [student, setStudent] = useState({});
  const [feedbackHistory, setFeedbackHistory] = useState([]);

  useEffect(() => {
    axios
      .get(`${baseURL}/${id}`)
      .then((response) => {
        setStudent(response.data);
        setFeedbackHistory(response.data.feedbackHistory?.reverse());
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);
  let { firstName, lastName, gender, teacher } = student;

  //pagination:
  const [CurrentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 8;
  const lastIndex = CurrentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = feedbackHistory?.slice(firstIndex, lastIndex);
  const npage = Math.ceil(feedbackHistory?.length / recordsPerPage);
  const numbers = [...Array(npage).keys()];
  return (
    <div className={styles.parentTable}>
      <div style={{ direction: "rtl", margin: "auto" }}>
        {!user?.student && (
          <p className={`fs-5 col-4`}>
            {gender == "female" ? "اسم الطالبة : " : "اسم الطالب : "}
            <strong>
              {firstName} {lastName}
            </strong>
          </p>
        )}
        {user?.student && (
          <p className={`fs-5 col-4`}>
            {teacher?.gender == "female" ? "اسم المعلمة : " : "اسم المعلم : "}
            <strong>
              {teacher?.firstName} {teacher?.lastName}
            </strong>
          </p>
        )}
      </div>
      <Table className={styles.tableDirction}>
        <thead>
          <tr className={styles.head}>
            <th>
              <FontAwesomeIcon className={styles.tableIcon} icon={faBookOpen} />
              التاريخ
            </th>
            <th>
              <FontAwesomeIcon className={styles.tableIcon} icon={faBell} />
              موضوع الحصة
            </th>
            <th>
              <FontAwesomeIcon className={styles.tableIcon} icon={faBell} />
              تعليق المعلم
            </th>
          </tr>
        </thead>
        <tbody>
          {records?.map((snapShot, index) => {
            let { date, subject, feedback } = snapShot;

            return (
              <tr className="" key={index}>
                <td>{date}</td>
                <td>{subject}</td>
                <td>{feedback}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <nav
        className={` ${styles.stBtn} d-flex justify-content-center align-items-center`}
      >
        <ul className="pagination">
          <li className="page-item">
            <a href="#" className="page-link text-dark" onClick={prePage}>
              السابق
            </a>
          </li>
          {numbers.map((n, i) => {
            return (
              <li
                className={`page-item ${CurrentPage === n + 1 ? "active" : ""}`}
                key={i}
              >
                <a
                  href="#"
                  className="page-link text-dark "
                  onClick={() => changePage(n + 1)}
                >
                  {n + 1}
                </a>
              </li>
            );
          })}

          <li className="page-item">
            <a href="#" className="page-link text-dark" onClick={nextPage}>
              التالي
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
  function prePage(e) {
    if (CurrentPage !== firstIndex + 1) {
      setCurrentPage(CurrentPage - 1);
    }
  }
  function nextPage() {
    if (CurrentPage !== numbers.length) {
      setCurrentPage(CurrentPage + 1);
    }
  }
  function changePage(id) {
    setCurrentPage(id);
  }
}
