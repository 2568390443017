import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Table } from "react-bootstrap";
import styles from "../../../../css/scheduleTable.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookOpen, faUser, faClock } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

export default function All() {
  let baseURL =
    "https://api.eshraqalazhar.com/classes";
  const [classes, setClasses] = useState([]);
  const [confirmString, setConfirmString] = useState("");
  let navigate = useNavigate();
  let { studentID, teacherID } = useParams();

  useEffect(() => {
    axios
      .get(`${baseURL}`)
      .then((response) => {
        if (teacherID) {
          setClasses(
            response.data.data.filter(
              (_class) => _class.teacher._id == teacherID
            )
          );
        } else if (studentID) {
          setClasses(
            response.data.data.filter(
              (_class) => _class.student._id == studentID
            )
          );
        } else {
          setClasses(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const [deletedClass, setDeletedClass] = useState([]);

  useEffect(() => {
    if (deletedClass) {
      setClasses(classes.filter((_class) => _class._id != deletedClass._id));
    }
  }, [deletedClass]);

  //pagination:
  const [CurrentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 5;
  const lastIndex = CurrentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = classes.slice(firstIndex, lastIndex);
  const npage = Math.ceil(classes.length / recordsPerPage);

  const numbers = [...Array(npage).keys()];
  return (
    <div className={styles.parentTable}>
      <Table className={styles.tableDirction}>
        <thead>
          <tr className={styles.head}>
            <th></th>
            {!teacherID && (
              <th>
                <FontAwesomeIcon className={styles.tableIcon} icon={faUser} />
                اسم المعلم
              </th>
            )}
            {!studentID && (
              <th>
                <FontAwesomeIcon className={styles.tableIcon} icon={faUser} />{" "}
                اسم الطالب
              </th>
            )}
            <th>
              <FontAwesomeIcon className={styles.tableIcon} icon={faBookOpen} />
              موضوع الحصة
            </th>
            <th>
              <FontAwesomeIcon className={styles.tableIcon} icon={faClock} />
              وقت الحصة
            </th>
            <th>
              <FontAwesomeIcon className={styles.tableIcon} icon={faClock} />
              المدة
            </th>
          </tr>
        </thead>
        <tbody>
          {records.map((cls, index) => {
            let { _id, student, teacher, startTime, classDuration, subject } =
              cls;
            let deleteClass = () => {
              const promptString = prompt("اكتب 'حذف' لتأكيد عملية الحذف:");
              setConfirmString(promptString);
              if (promptString === "حذف") {
                // Perform delete operation
                axios
                  .delete(`${baseURL}/${_id}`)
                  .then((response) => {
                    setDeletedClass(cls);
                    alert("تم الحذف");
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              } else {
                alert("لم يتم كتابتها بشكل صحيح");
              }
            };

            let editClass = () => {
              navigate(
                `/dashboard/classes/classForm/student/${student._id}/class/${_id}`
              );
            };
            return (
              <tr className="" key={_id}>
                <td>
                  <div>
                    <button
                      className="btn btn-danger mt-2"
                      onClick={deleteClass}
                    >
                      حذف
                    </button>
                  </div>
                  <div>
                    <button
                      className="btn btn-warning mt-2"
                      onClick={editClass}
                    >
                      تعديل
                    </button>
                  </div>
                </td>
                {!teacherID && (
                  <td>
                    {teacher.firstName} {teacher.lastName}
                  </td>
                )}
                {!studentID && (
                  <td>
                    {student.firstName} {student.lastName}
                  </td>
                )}
                <td>{subject}</td>
                <td>{startTime}</td>
                <td>{classDuration}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <nav className="d-flex justify-content-center align-items-center">
        <ul className="pagination">
          <li className="page-item">
            <a href="#" className="page-link text-dark" onClick={prePage}>
              السابق
            </a>
          </li>
          {numbers.map((n, i) => {
            return (
              <li
                className={`page-item ${CurrentPage === n + 1 ? "active" : ""}`}
                key={i}
              >
                <a
                  href="#"
                  className="page-link text-dark "
                  onClick={() => changePage(n + 1)}
                >
                  {n + 1}
                </a>
              </li>
            );
          })}

          <li className="page-item">
            <a href="#" className="page-link text-dark" onClick={nextPage}>
              التالي
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
  function prePage(e) {
    if (CurrentPage !== firstIndex + 1) {
      setCurrentPage(CurrentPage - 1);
    }
  }
  function nextPage() {
    if (CurrentPage !== numbers.length) {
      setCurrentPage(CurrentPage + 1);
    }
  }
  function changePage(id) {
    setCurrentPage(id);
  }
}
