import { combineReducers } from "redux";
import conversationSlice from "./slices/conversationSlice";
import messageReducer from "./slices/messageSlice";
import teacherReducer from "./slices/teacherSlice";

const rootRuducer = combineReducers({
  conversation: conversationSlice,
  message: messageReducer,
  teacher: teacherReducer,
});

export default rootRuducer;
