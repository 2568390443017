import React from "react";
import styles from "../../css/footer.module.css";
import { NavLink } from 'react-router-dom';
import { useAuthContext } from '../../hooks/useAuthContext'

export default function FooterAr() {
  
  const {user} = useAuthContext();
  
  return (
    <div className={styles.backGround}>
      <div className="container">
        <div className="row p-4 text-light">
          <div className="footerBOX col-lg-3 col-md-6 col-sm-12">
            <h3 className="d-flex aline-item-center justify-content-center">
              <img
                className={styles.img}
                src="logo eshraq final 4.png"
                alt="logo"
              />
            </h3>
          </div>
          <div
            className=" col-lg-3 col-md-6 col-sm-12"
            style={{ direction: "rtl" }}
          >
            <h4 className={styles.fonts}>من نحن </h4>
            <p className={styles.pragraph}>
              إشراق الأزهر هي أكاديمية متخصصة في تعليم القرآن الكريم والقراءات
              القرآنية والعلوم الشرعية والعربية من خلال مناهج دراسية وسطية تجمع
              بين الأصالة والمعاصرة
            </p>
          </div>
          <div className="footerBOX col-lg-3 col-md-6 col-sm-12">
            <h4 className={styles.fonts}>تابعونا </h4>
            <div className={styles.footerBOX}>
              <a
                target="blank"
                className={styles.icon}
                href="https://www.facebook.com/إشراق-الأزهر-109101188494994/"
              >
                {" "}
                <img
                  className={styles.icon}
                  src="https://cdn-icons-png.flaticon.com/512/5968/5968764.png"
                  alt="icon"
                />
              </a>
              <a
                target="blank"
                className={styles.icon}
                href="https://www.instagram.com/eshraqalazhar/"
              >
                {" "}
                <img
                  className={styles.icon}
                  src="https://cdn-icons-png.flaticon.com/512/2111/2111463.png"
                  alt="icon"
                />
              </a>
              <a
                target="blank"
                className={styles.icon}
                href="https://www.youtube.com/@user-ez9xp1ud6r"
              >
                {" "}
                <img
                  className={styles.icon}
                  src="https://cdn-icons-png.flaticon.com/512/124/124015.png"
                  alt="icon"
                />
              </a>
            </div>
          </div>
          <div className="footerBOX col-lg-3 col-md-6 col-sm-12">
            <h4 className={styles.fonts}>
              {" "}
              {user ? "الملف الشخصي" : "انضم لنا"}{" "}
            </h4>
            <div className={styles.freeDemo}>
              <NavLink to={user ? "/teacher/students" : "/freedemo"}>
                {user ? user.email : "تسجيل حصة تجريبية"}
                <i className="fa-solid fa-arrow-right"></i>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.copyright}>
        © 2023.{" "}
          <strong>إشراق الأزهر</strong>
        . جميع الحقوق محفوظة لنا
      </div>
      <div className={styles.box}>
        <a href="#">
          <i className="fa-solid fa-arrow-up"></i>
        </a>
      </div>
    </div>
  );
}
