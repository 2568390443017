import React from "react";
import Carousel from "react-bootstrap/Carousel";
import styles from "../../css/slider.module.css";
export default function SliderAr() {
  return (
    <div className="slider">
      <Carousel className={styles.sliderImg}>
        <Carousel.Item className={styles.sliderImg}>
          <img
            className="d-block w-100"
            src="eshraq-cover-site1.png"
            alt="First slide"
          />
        </Carousel.Item>
        <Carousel.Item className={styles.sliderImg}>
          <img
            className="d-block w-100"
            src="eshraq-site-2.png"
            alt="Second slide"
          />

        </Carousel.Item>
        <Carousel.Item className={styles.sliderImg}>
          <img
            className="d-block w-100"
            src="editتصميم-موقع-3.png"
            alt="Third slide"
          />
        </Carousel.Item>
      </Carousel>
    </div>
  );
}
