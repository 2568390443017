import axios from "axios";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import styles from "../../../css/scheduleTable.module.css";
import { Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBookOpen,
  faClock,
  faQuestionCircle,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
export default function DashboardFreeDemos() {
  let baseURL =
    "https://api.eshraqalazhar.com/freeDemo";
  let navigate = useNavigate();
  const [freeDemos, setFreeDemos] = useState([]);
  const [confirmString, setConfirmString] = useState("");

  useEffect(() => {
    axios
      .get(baseURL)
      .then((response) => {
        setFreeDemos(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const [rejectedStudent, setRejectedStudent] = useState([]);

  useEffect(() => {
    if (rejectedStudent) {
      setFreeDemos(
        freeDemos.filter((freeDemo) => freeDemo._id != rejectedStudent._id)
      );
    }
  }, [rejectedStudent]);

  const dateAndTime = new Date();

  //pagination:
  const [CurrentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 5;
  const lastIndex = CurrentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = freeDemos.slice(firstIndex, lastIndex);
  const npage = Math.ceil(freeDemos.length / recordsPerPage);

  const numbers = [...Array(npage).keys()];

  return (
    <div className={styles.parentTable}>
      <Table className={styles.tableDirction}>
        <thead>
          <tr className={styles.head}>
            <th>
              <FontAwesomeIcon className={styles.tableIcon} icon={faBookOpen} />{" "}
              المسلسل
            </th>
            <th>
              <FontAwesomeIcon className={styles.tableIcon} icon={faUser} /> اسم
              الطالب
            </th>
            <th>
              <FontAwesomeIcon
                className={styles.tableIcon}
                icon={faQuestionCircle}
              />{" "}
              السن
            </th>
            <th>
              <FontAwesomeIcon
                className={styles.tableIcon}
                icon={faQuestionCircle}
              />{" "}
              الإيميل
            </th>
            <th>
              <FontAwesomeIcon className={styles.tableIcon} icon={faClock} />{" "}
              النوع
            </th>
            <th>
              <FontAwesomeIcon className={styles.tableIcon} icon={faClock} />{" "}
              رقم التليفون
            </th>
            <th>
              <FontAwesomeIcon className={styles.tableIcon} icon={faClock} />{" "}
              البلد
            </th>
            <th>
              <FontAwesomeIcon className={styles.tableIcon} icon={faClock} /> من
              خلال
            </th>
            <th>
              <FontAwesomeIcon className={styles.tableIcon} icon={faClock} />{" "}
              عمليات
            </th>
          </tr>
        </thead>
        <tbody>
          {records.map((freeDemo, index) => {
            let deleteStudent = () => {
              const promptString = prompt("اكتب 'حذف' لتأكيد عملية الحذف:");
              setConfirmString(promptString);
              if (promptString === "حذف") {
                // Perform delete operation
                axios
                  .delete(`${baseURL}/${_id}`)
                  .then((response) => {
                    setRejectedStudent(freeDemo);
                    alert("تم الحذف");
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              } else {
                alert("لم يتم كتابتها بشكل صحيح");
              }
            };

            let acceptStudent = () => {
              navigate(
                `/dashboard/studentForm/student/${_id}/way/${howDidYouHearAboutUs}`
              );
            };
            let {
              firstName,
              lastName,
              email,
              age,
              gender,
              phoneNumber,
              country,
              _id,
              howDidYouHearAboutUs,
            } = freeDemo;
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>
                  {firstName} {lastName}{" "}
                </td>
                <td>{age}</td>
                <td>
                  <a target="_blank" href={`mailto:${email}`} className="">
                    {email}
                  </a>
                </td>
                <td>{gender == "male" ? "ذكر" : "أنثى"}</td>
                <td>
                  <a
                    target="_blank"
                    href={`https://api.whatsapp.com/send?phone=${phoneNumber}`}
                    className=""
                  >
                    {phoneNumber}
                  </a>
                </td>
                <td>{country}</td>
                <td>{howDidYouHearAboutUs}</td>
                <td>
                  <button
                    className="btn btn-warning"
                    style={{ display: "block" }}
                    onClick={acceptStudent}
                  >
                    قبول
                  </button>
                  <button
                    className="btn btn-danger mt-2"
                    style={{ display: "block" }}
                    onClick={deleteStudent}
                  >
                    رفض
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <nav className="d-flex justify-content-center align-items-center">
        <ul className="pagination">
          <li className="page-item">
            <a href="#" className="page-link text-dark" onClick={prePage}>
              السابق
            </a>
          </li>
          {numbers.map((n, i) => {
            return (
              <li
                className={`page-item ${CurrentPage === n + 1 ? "active" : ""}`}
                key={i}
              >
                <a
                  href="#"
                  className="page-link text-dark "
                  onClick={() => changePage(n + 1)}
                >
                  {n + 1}
                </a>
              </li>
            );
          })}

          <li className="page-item">
            <a href="#" className="page-link text-dark" onClick={nextPage}>
              التالي
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );

  function prePage(e) {
    if (CurrentPage !== firstIndex + 1) {
      setCurrentPage(CurrentPage - 1);
    }
  }
  function nextPage() {
    if (CurrentPage !== numbers.length) {
      setCurrentPage(CurrentPage + 1);
    }
  }
  function changePage(id) {
    setCurrentPage(id);
  }
}
