import React from 'react'
import '../../css/aboutUsAr.css'
export default function AboutUsAr() {
  return (
    <div className="aboutUs">
      <div className="container">
        <h2 className="mt-5">أكاديمية إشراق الأزهر</h2>
        <div className="row mb-2 aboutUsFelex">
          <div className="col-md-6 col-sm-12 text">
            <p className="fs-4 mb-5">
              إشراق الأزهر هي أكاديمية متخصصة في تعليم القرآن الكريم والقراءات
              القرآنية والعلوم الشرعية والعربية من خلال مناهج دراسية وسطية تجمع
              بين الأصالة والمعاصرة
            </p>
          </div>
          <div className="col-md-6 col-sm-12 imgBox">
            <img
              className="aboutImg"
              src="https://c0.wallpaperflare.com/preview/267/712/965/islamic-prayer-dusk-sun.jpg"
              alt=""
            />
          </div>
        </div>
        <h2 className="mt-5">رؤيتنا ومهمتنا</h2>
        <div className="row mb-4 aboutUsFelex">
          <div className="col-md-6 col-sm-12 imgBox">
            <img
              className="aboutImg"
              src="https://images.pexels.com/photos/1047284/pexels-photo-1047284.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
              alt=""
            />
          </div>
          <div className="col-md-6 col-sm-12 text">
            <p className="fs-4 mb-5">
              نسعى ونؤمل إعداد جيل من الطلاب الماهر لغويا وشرعيا وثقافياً وليكون
              هذا الجيل المعد لديه القدرة على التفكير العلمي الإبداعي النقدي وحل
              المشكلات وكذلك التزود بالمهارات التي تساعدهم على القيام بدور
              فعال في مجتمعاتهم{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
