import React, { useEffect, useState } from "react";
import axios from "axios";
import { Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

const initialFormState = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  age: "",
  gender: "",
  joiningDate: "",
  cellPhoneNumber: "",
  zoomEmailID: "",
  city: "",
  country: "مصر",
  ratePerHour: "", //ch
  availableTimes: [{ dayOfWeek: "", startTime: "", endTime: "" }],
};
const countriesCodes = [
  { السعودية: 966 },
  { الكويت: 965 },
  { قطر: 974 },
  { البحرين: 973 },
  { الإمارات: 971 },
  { تونس: 216 },
  { مصر: 20 },
  { عمان: 968 },
  { العراق: 964 },
  { اليمن: 967 },
  { سوريا: 963 },
  { ليبيا: 218 },
  { جزرالقمر: 269 },
  { الأردن: 962 },
  { الجزائر: 213 },
  // Add more timezones here
];

export default function TeacherForm({ candidateId }) {
  let baseURL = "https://api.eshraqalazhar.com/teachers";
  const [teacher, setTeacher] = useState();
  const [formState, setFormState] = useState(initialFormState);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(null);
  const [error, setError] = useState(null);

  let { id } = useParams();
  let navigate = useNavigate();

  const [phoneCode, setPhoneCode] = useState(id ? "" : "20");

  const showPhoneCode = (country) => {
    const countryCode = countriesCodes.find(
      (codeObj) => Object.keys(codeObj)[0] === country
    )?.[country];
    setPhoneCode(countryCode);
  };

  const handleInputChange = (event, index) => {
    const { name, value } = event.target;
    if (name === "dayOfWeek" || name === "startTime" || name === "endTime") {
      const availableTimes = [...formState.availableTimes];
      availableTimes[index][name] = value;
      setFormState({ ...formState, availableTimes });
    } else {
      if (name === "country") {
        showPhoneCode(value);
      }
      setFormState({ ...formState, [name]: value });
    }
  };

  const handleAddAvailableTime = () => {
    setFormState({
      ...formState,
      availableTimes: [
        ...formState.availableTimes,
        { dayOfWeek: "", startTime: "", endTime: "" },
      ],
    });
  };

  const handleRemoveAvailableTime = (index) => {
    const availableTimes = [...formState.availableTimes];
    availableTimes.splice(index, 1);
    setFormState({ ...formState, availableTimes });
  };

  const validateForm = () => {
    const errors = {};
    if (!formState.firstName) {
      errors.firstName = "أدخل الاسم الأول";
    }
    if (!formState.lastName) {
      errors.lastName = "أدخل الاسم الثاني";
    }
    if (!formState.email) {
      errors.email = "أدخل البريد الألكتروني";
    } else if (!/\S+@\S+\.\S+/.test(formState.email)) {
      errors.email = "الإيميل غير صحيح";
    }
    if (!formState.password) {
      errors.password = "أدخل كلمة المرور";
    } else if (formState.password.length < 8) {
      errors.password = "كلمة المرور يجب ألا تقل عن 8 أحرف";
    }
    if (!formState.age) {
      errors.age = "أدخل العمر";
    } else if (
      isNaN(formState.age) ||
      formState.age < 1 ||
      formState.age > 100
    ) {
      errors.age = " غير صحيح";
    }
    if (!formState.gender) {
      errors.gender = "أدخل النوع";
    }
    if (!formState.joiningDate) {
      errors.joiningDate = "أدخل تاريخ الانضمام";
    }
    if (!formState.cellPhoneNumber) {
      errors.cellPhoneNumber = "أدخل رقم الهاتف";
    } else if (
      isNaN(formState.cellPhoneNumber) ||
      formState.cellPhoneNumber.length > 15
    ) {
      errors.cellPhoneNumber = "رقم الهاتف غير صحيح";
    }
    if (!formState.zoomEmailID) {
      errors.zoomEmailID = "أدخل لينك الحصة";
    } else if (!/\S+@\S+\.\S+/.test(formState.zoomEmailID)) {
      errors.zoomEmailID = "لينك الحصة غير صحيح";
    }
    if (!formState.city) {
      errors.city = "أدخل اسم المدينة";
    }
    if (!formState.country) {
      errors.country = "أدخل اسم الدولة";
    }
    if (!formState.ratePerHour) {
      errors.ratePerHour = "أدخل سعر الساعة";
    } else if (
      isNaN(formState.ratePerHour) ||
      formState.ratePerHour.length > 5
    ) {
      errors.cellPhoneNumber = "سعر الساعة غير صحيح";
    }
    return errors;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const errors = validateForm();
    setErrors(errors);

    if (Object.keys(errors).length === 0) {
      formState.cellPhoneNumber = phoneCode + formState.cellPhoneNumber;
      setIsLoading(true);

      if (id) {
        try {
          await axios.put(`${baseURL}/${id}`, formState);
          setFormState(initialFormState);
          setIsLoading(false);

          alert("تم تحديث بيانات المعلم بنجاح");
          navigate(`/dashboard/teachers`);
        } catch (error) {
          console.error(error);
          setError(error);
          setIsLoading(false);
        }
      } else {
        try {
          await axios.post(`${baseURL}`, formState);
          setFormState(initialFormState);
          setIsLoading(false);

          alert("تمت إضافة بيانات المعلم بنجاح");
          navigate(`/dashboard/teachers`);
        } catch (error) {
          console.error(error);
          setError(error);
          setIsLoading(false);
        }
      }
    }
  };

  useEffect(() => {
    if (id) {
      axios
        .get(`${baseURL}/${id}`)
        .then((response) => {
          setTeacher(response.data);
          setFormState(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [id]);

  return (
    <div style={{ direction: "rtl", width: "50%", margin: "auto" }}>
      <Form
        onSubmit={handleSubmit}
        className="justify-content-center align-items-center"
      >
        <Form.Group
          controlId="firstName"
          className="d-flex justify-content-between align-items-center mt-4"
        >
          <Form.Label>الاسم الاول</Form.Label>
          <div
            className="d-flex"
            style={{ flexDirection: "column", width: "80%" }}
          >
            <Form.Control
              type="text"
              name="firstName"
              defaultValue={teacher?.firstName}
              onChange={handleInputChange}
              isInvalid={errors.firstName}
            />
            <Form.Control.Feedback type="invalid">
              {errors.firstName}
            </Form.Control.Feedback>
          </div>
        </Form.Group>

        <Form.Group
          controlId="lastName"
          className="d-flex justify-content-between align-items-center mt-4"
        >
          <Form.Label>الاسم الثاني</Form.Label>
          <div
            className="d-flex"
            style={{ flexDirection: "column", width: "80%" }}
          >
            <Form.Control
              type="text"
              name="lastName"
              defaultValue={teacher?.lastName}
              onChange={handleInputChange}
              isInvalid={errors.lastName}
            />
            <Form.Control.Feedback type="invalid">
              {errors.lastName}
            </Form.Control.Feedback>
          </div>
        </Form.Group>

        <Form.Group
          controlId="email"
          className="d-flex justify-content-between align-items-center mt-4"
        >
          <Form.Label>البريد الالكتروني</Form.Label>
          <div
            className="d-flex"
            style={{ flexDirection: "column", width: "80%" }}
          >
            <Form.Control
              type="email"
              name="email"
              defaultValue={teacher?.email}
              onChange={handleInputChange}
              isInvalid={errors.email}
            />
            <Form.Control.Feedback type="invalid">
              {errors.email}
            </Form.Control.Feedback>
          </div>
        </Form.Group>

        <Form.Group
          controlId="password"
          className="d-flex justify-content-between align-items-center mt-4"
        >
          <Form.Label>كلمة المرور</Form.Label>
          <div
            className="d-flex"
            style={{ flexDirection: "column", width: "80%" }}
          >
            <Form.Control
              type="password"
              name="password"
              defaultValue={teacher?.password}
              onChange={handleInputChange}
              isInvalid={errors.password}
            />
            <Form.Control.Feedback type="invalid">
              {errors.password}
            </Form.Control.Feedback>
          </div>
        </Form.Group>

        <Form.Group
          controlId="age"
          className="d-flex justify-content-between align-items-center mt-4"
        >
          <Form.Label>العمر</Form.Label>
          <div
            className="d-flex"
            style={{ flexDirection: "column", width: "80%" }}
          >
            <Form.Control
              type="text"
              name="age"
              defaultValue={teacher?.age}
              onChange={handleInputChange}
              isInvalid={errors.age}
            />
            <Form.Control.Feedback type="invalid">
              {errors.age}
            </Form.Control.Feedback>
          </div>
        </Form.Group>

        <Form.Group
          controlId="gender"
          className="d-flex justify-content-between align-items-center mt-4"
        >
          <Form.Label>النوع</Form.Label>
          <div
            className="d-flex"
            style={{ flexDirection: "column", width: "80%" }}
          >
            <Form.Control
              as="select"
              name="gender"
              value={formState?.gender}
              onChange={handleInputChange}
              isInvalid={errors.gender}
            >
              <option value="">حدد النوع</option>
              <option value="male">ذكر</option>
              <option value="female">انثى</option>
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {errors.gender}
            </Form.Control.Feedback>
          </div>
        </Form.Group>

        <Form.Group
          controlId="joiningDate"
          className="d-flex justify-content-between align-items-center mt-4"
        >
          <Form.Label>تاريخ الانضمام</Form.Label>
          <div
            className="d-flex"
            style={{ flexDirection: "column", width: "80%" }}
          >
            <Form.Control
              type="date"
              name="joiningDate"
              defaultValue={teacher?.joiningDate}
              onChange={handleInputChange}
              isInvalid={errors.joiningDate}
            />
            <Form.Control.Feedback type="invalid">
              {errors.joiningDate}
            </Form.Control.Feedback>
          </div>
        </Form.Group>

        <Form.Group
          controlId="zoomEmailID"
          className="d-flex justify-content-between align-items-center mt-4"
        >
          <Form.Label>ايميل زووم</Form.Label>
          <div
            className="d-flex"
            style={{ flexDirection: "column", width: "80%" }}
          >
            <Form.Control
              type="email"
              name="zoomEmailID"
              defaultValue={teacher?.zoomEmailID}
              onChange={handleInputChange}
              isInvalid={!!errors.zoomEmailID}
            />
            <Form.Control.Feedback type="invalid">
              {errors.zoomEmailID}
            </Form.Control.Feedback>
          </div>
        </Form.Group>

        <Form.Group
          controlId="city"
          className="d-flex justify-content-between align-items-center mt-4"
        >
          <Form.Label>المدينة</Form.Label>
          <div
            className="d-flex"
            style={{ flexDirection: "column", width: "80%" }}
          >
            <Form.Control
              type="text"
              name="city"
              defaultValue={teacher?.city}
              onChange={handleInputChange}
              isInvalid={!!errors.city}
            />
            <Form.Control.Feedback type="invalid">
              {errors.city}
            </Form.Control.Feedback>
          </div>
        </Form.Group>

        <Form.Group
          controlId="country"
          className="d-flex justify-content-between align-items-center mt-4"
        >
          <Form.Label>الدولة</Form.Label>
          <div
            className="d-flex"
            style={{ flexDirection: "column", width: "80%" }}
          >
            <Form.Control
              as="select"
              name="country"
              value={formState?.country}
              onChange={handleInputChange}
              isInvalid={!!errors.country}
            >
              <option value="">اختر الدولة</option>
              {countriesCodes.map((country) => (
                <option
                  key={Object.keys(country)[0]}
                  value={Object.keys(country)[0]}
                >
                  {Object.keys(country)[0]}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {errors.country}
            </Form.Control.Feedback>
          </div>
        </Form.Group>

        <Form.Group
          controlId="cellPhoneNumber"
          className="d-flex justify-content-between align-items-center mt-4"
        >
          <Form.Label>رقم الواتس آب</Form.Label>
          <div
            className="d-flex"
            style={{ flexDirection: "column", width: "60%" }}
          >
            <Form.Control
              type="text"
              name="cellPhoneNumber"
              placeholder="اكمل الرقم من بعد كود الدولة"
              defaultValue={teacher?.cellPhoneNumber}
              onChange={handleInputChange}
              isInvalid={!!errors.cellPhoneNumber}
            />
            <Form.Control.Feedback type="invalid">
              {errors.cellPhoneNumber}
            </Form.Control.Feedback>
          </div>
          <Form.Group controlId="phoneNumberCodeGroup" style={{ width: "12%" }}>
            <Form.Control
              className="inputDir"
              type="tel"
              name="phoneNumberCode"
              value={`${phoneCode || ""}+`}
              disabled
            />
          </Form.Group>
        </Form.Group>

        <Form.Group
          controlId="ratePerHour"
          className="d-flex justify-content-between align-items-center mt-4"
        >
          <Form.Label>سعر الساعة</Form.Label>
          <div
            className="d-flex"
            style={{ flexDirection: "column", width: "80%" }}
          >
            <Form.Control
              type="text"
              name="ratePerHour"
              defaultValue={teacher?.ratePerHour}
              onChange={handleInputChange}
              isInvalid={!!errors.ratePerHour}
            />
            <Form.Control.Feedback type="invalid" style={{ width: "80%" }}>
              {errors.ratePerHour}
            </Form.Control.Feedback>
          </div>
        </Form.Group>

        <Form.Group controlId="availableTimes">
          <Form.Label className="">الاوقات المتاحة</Form.Label>
          {formState.availableTimes.map((availableTime, index) => (
            <div key={index}>
              <hr />
              <div className="d-flex justify-content-between">
                <h5>اليوم {index + 1}</h5>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => handleRemoveAvailableTime(index)}
                >
                  احذف اليوم
                </button>
              </div>
              <div className="mb-3">
                <Form.Label>ايام الاسبوع</Form.Label>
                <Form.Control
                  as="select"
                  name="dayOfWeek"
                  value={availableTime.dayOfWeek}
                  onChange={(event) => handleInputChange(event, index)}
                  required
                >
                  <option value="">-- حدد يوم الأسبوع --</option>
                  <option value="Monday">الاثنين</option>
                  <option value="Tuesday">الثلاثاء</option>
                  <option value="Wednesday">الأربعاء</option>
                  <option value="Thursday">الخميس</option>
                  <option value="Friday">الجمعة</option>
                  <option value="Saturday">السبت</option>
                  <option value="Sunday">الأحد</option>
                </Form.Control>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <Form.Group controlId={`availableTimes[${index}].startTime`}>
                    <Form.Label>بداية الوقت</Form.Label>
                    <Form.Control
                      type="time"
                      name="startTime"
                      value={availableTime.startTime}
                      onChange={(event) => handleInputChange(event, index)}
                      required
                    />
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group controlId={`availableTimes[${index}].endTime`}>
                    <Form.Label>نهاية الوقت</Form.Label>
                    <Form.Control
                      type="time"
                      name="endTime"
                      value={availableTime.endTime}
                      onChange={(event) => handleInputChange(event, index)}
                      required
                    />
                  </Form.Group>
                </div>
              </div>
            </div>
          ))}
          <button
            type="button"
            className="btn btn-warning mt-2"
            onClick={handleAddAvailableTime}
          >
            اضافة الوقت المتاح
          </button>
        </Form.Group>
        <div className="w-100 justify-content-center text-center">
          <div className="errorMsgDiv">
            {error && (
              <div className="error">
                {"برجاء التأكد من صحة البيانات المدخلة ثم حاول مرة أخرى"}
              </div>
            )}
          </div>
          <button
            type="submit"
            className="btn btn-success"
            disabled={isLoading}
          >
            {id ? "تعديل المعلم" : "اضافة المعلم "}
          </button>
        </div>
      </Form>
    </div>
  );
}
