import React from "react";
import styles from "../../../css/scheduleTable.module.css";
import { NavLink, Outlet } from "react-router-dom";
import { useAuthContext } from "../../../hooks/useAuthContext";
import imglogo from "../../../assets/images/logo eshraq final 3.png";
export default function StudentPage() {
  const { user } = useAuthContext();
  var today = new Date();
  var days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  var dayName = days[today.getDay()];
  var dd = today.getDate();
  var mm = today.getMonth() + 1; //January is 0!
  var yyyy = today.getFullYear();
  today = `(${dayName}) ${dd} / ${mm} / ${yyyy}`;
  return (
    <div className={styles.parentTable}>
      <div className="container">
        <div className={` ${styles.logo}  ${styles.dirction} col-12 `}>
          <img className={styles.img} src={imglogo} alt="logo" />
        </div>
        <div className={`${styles.headerOfstudentTable}`}>
          <div className={`${styles.text}  fs-3 col-5`}>
            {user.studentObj.gender == "female"
              ? "مرحبا بالطالبة : "
              : "مرحبا بالطالب : "}
            <strong>{user.name}</strong>
          </div>
          <div className={`${styles.date} col-5`}> {today}</div>
        </div>
      </div>
      <div className="container">
        <div className={styles.navStudent}>
          <NavLink className={`${styles.marginBott} ${styles.linkOfStudent} nav-link`} to="classes">
            حصصى
          </NavLink>
          <NavLink
            className={`${styles.marginBott} ${styles.linkOfStudent} nav-link`}
            to={`feedbackHistory/${user.studentObj?._id}`}
          >
            تعليقات المعلم
          </NavLink>
          <NavLink
            className={`${styles.marginBott} ${styles.linkOfStudent} nav-link`}
            to={`studentAccounting/${user.studentObj?._id}`}
          >
            الحسابات
          </NavLink>
          <NavLink
            className={`${styles.marginBott} ${styles.linkOfStudent} nav-link`}
            to="personalInfo"
          >
            معلوماتي الشخصية
          </NavLink>
        </div>
        <div>
          <Outlet />
        </div>
      </div>
    </div>
  );
}
