import React from "react";
import "../../css/teacherHomePage.css";
import Carousel from 'react-bootstrap/Carousel';
export default function TeachersHomePage() {
  return (
    <div id="teachers">
      <h2 className="my-3">معلمونا الأفاضل</h2>
      <div className="container my-5">
        <Carousel>
          <Carousel.Item className="teacherSlider">
            <div className="align-items-center d-flex justify-content-between flex-wrap">
              <div className="box-12-1 col-lg-3 col-md-3 col-sm-12">
                <div className="flex">
                  <img
                    src="https://cdn4.iconfinder.com/data/icons/people-2-5/36/113-512.png"
                    alt="...."
                  />
                  <div className="inner-box-12">
                    <h4 className="fs-5">الشيخ / أحمد بلح </h4>
                    <span> معلم قرآن كريم ومقرئ للقراءات العشر</span>
                  </div>
                </div>
                <div className="box-13">
                  <p className="text-center paragraph text-dark fw-bold">
                    حاصل علي ليسانس القراءات وعلوم القرآن جامعة الأزهر خبره أكثر
                    من عشر سنوات في تعليم القرآن بالمملكة السعودية ومصر
                  </p>
                </div>
              </div>
              <div className="box-12-1 col-lg-3 col-md-3 col-sm-12 align-items-center d-flex justify-content-between flex-column">
                <div className="flex ">
                  <img
                    src="https://cdn4.iconfinder.com/data/icons/people-2-5/36/113-512.png"
                    alt=""
                  />
                  <div className="inner-box-12">
                    <h4 className="fs-5">الشيخ/ عمار أبو زيد </h4>
                    <span> معلم قرآن كريم ومقريء القراءات العشر </span>
                  </div>
                </div>
                <div className="box-13">
                  <p className="text-center paragraph   text-dark fw-bold">
                    ليسانس القراءات وعلوم القرآن من جامعة الأزهر خبرة أكثر من 10
                    سنوات في تعليم القرآن
                  </p>
                </div>
              </div>
              <div className="box-12-1 col-lg-3 col-md-3 col-sm-12 align-items-center d-flex justify-content-between flex-column">
                <div className="flex">
                  <img
                    src="https://th.bing.com/th/id/OIP.fh3THt7pPdVzctV_kezkKwHaHa?w=214&h=214&c=7&r=0&o=5&dpr=1.4&pid=1.7"
                    alt=""
                  />
                  <div className="inner-box-12">
                    <h4 className="fs-5"> أ/ عزة الشربيني</h4>
                    <span> معلمة قراءن وقراءات</span>
                  </div>
                </div>
                <div className="box-13">
                  <p className="text-center paragraph  text-dark fw-bold">
                    مجازة بالقراءات العشرة إفرادا خبرة ٢٥ عاما في تعليم القرآن
                    بالمملكة السعودية ومصر بوزارة المعارف ودار المعصراوي وغيرها
                    تجيز معظم متون التجويد والقراءات القرآنية
                  </p>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item className="teacherSlider">
            <div className="align-items-center d-flex justify-content-between flex-wrap">
              <div className="box-12-1 col-lg-3 col-md-3 col-sm-12 align-items-center d-flex justify-content-between flex-column">
                <div className="flex">
                  <img
                    src="https://cdn4.iconfinder.com/data/icons/people-2-5/36/113-512.png"
                    alt="...."
                  />
                  <div className="inner-box-12">
                    <h4 className="fs-5">الشيخ / محمود ممتاز</h4>
                    <span> معلم قرآن كريم ومقرئ للقراءات العشر</span>
                  </div>
                </div>
                <div className="box-13">
                  <p className="text-center paragraph   text-dark fw-bold">
                    حاصل علي ليسانس القراءات وعلوم القرآن جامعة الأزهر خبره أكثر
                    من عشر سنوات في تعليم القرآن ومتون التجوي{" "}
                  </p>
                </div>
              </div>
              <div className="box-12-1 col-lg-3 col-md-3 col-sm-12 align-items-center d-flex justify-content-between flex-column">
                <div className="flex">
                  <img
                    src="https://cdn4.iconfinder.com/data/icons/people-2-5/36/113-512.png"
                    alt=""
                  />
                  <div className="inner-box-12">
                    <h4 className="fs-5">الشيخ / عبد القيوم</h4>
                    <span>
                      {" "}
                      معلم القرآن الكريم والعلوم العربية والعلوم الشرعية
                    </span>
                  </div>
                </div>
                <div className="box-13">
                  <p className="text-center paragraph   text-dark fw-bold">
                    ماجستير في البلاغة والنقد من جامعة الأزهر
                    خبرة أكثر من 7 سنوات{" "}
                  </p>
                </div>
              </div>
              <div className="box-12-1 col-lg-3 col-md-3 col-sm-12 align-items-center d-flex justify-content-between flex-column">
                <div className="flex">
                  <img
                    src="https://cdn4.iconfinder.com/data/icons/people-2-5/36/113-512.png"
                    alt=""
                  />
                  <div className="inner-box-12">
                    <h4 className="fs-5">الشيخ / عبد الله محمد إبراهيم </h4>
                    <span>
                      معلم قرآن كريم ومقرئ للقراءات العشر الكبرى والصغرى{" "}
                    </span>
                  </div>
                </div>
                <div className="box-13">
                  <p className="text-center paragraph  text-dark fw-bold">
                    حاصل علي ليسانس القراءات وعلوم القرآن جامعة الأزهر خبره أكثر
                    من عشر سنوات صاحب تصانيف في علم التجويد والقراءات القرآنية{" "}
                  </p>
                </div>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
    </div>
  );
}
