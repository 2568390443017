import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import styles from "../../../css/scheduleTable.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import {
  faBookOpen,
  faUser,
  faBell,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

export default function TeachersAccounting() {
  let baseURL = "https://api.eshraqalazhar.com/teachers";
  const [teachers, setTeachers] = useState([]);
  useEffect(() => {
    axios
      .get(`${baseURL}`)
      .then((response) => {
        setTeachers(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const teacherGotPaid = (teacherId) => {
    const gotPaid = true;

    axios
      .put(`${baseURL}/${teacherId}`, {
        gotPaid,
      })
      .then((response) => {
        setTeachers((teacherOldData) => {
          const teacherUpdatedData = [...teacherOldData];
          const teacherIndex = teacherUpdatedData.findIndex(
            (teacher) => teacher._id === teacherId
          );
          teacherUpdatedData[teacherIndex].gotPaid = gotPaid;
          return teacherUpdatedData;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const resetAccounts = () => {
    axios
      .put(`${baseURL}/accounts/reset`)
      .then((response) => {
        setTeachers(response.data.teachers);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className={styles.parentTable}>
      <Button
        className="btn btn-danger mb-3 align-items-center"
        onClick={resetAccounts}
      >
        إعادة ضبط
      </Button>
      <Table className={styles.tableDirction}>
        <thead>
          <tr className={styles.head}>
            <th>
              <FontAwesomeIcon className={styles.tableIcon} icon={faBookOpen} />{" "}
              المسلسل
            </th>
            <th>
              <FontAwesomeIcon className={styles.tableIcon} icon={faUser} /> اسم
              المعلم
            </th>
            <th>
              <FontAwesomeIcon className={styles.tableIcon} icon={faBell} />{" "}
              الراتب
            </th>
            <th>
              <FontAwesomeIcon
                className={styles.tableIcon}
                icon={faQuestionCircle}
              />{" "}
              حالة الدفع
            </th>
            <th>
              <FontAwesomeIcon
                className={styles.tableIcon}
                icon={faQuestionCircle}
              />{" "}
              من خلال
            </th>
          </tr>
        </thead>
        <tbody>
          {teachers.map((teacher, index) => {
            let { _id, firstName, lastName, salary, gotPaid } = teacher;

            return (
              <tr className="" key={_id}>
                <td>{index + 1}</td>
                <td>
                  {firstName} {lastName}
                </td>
                <td>{salary}</td>
                <td>{gotPaid ? "تم الدفع" : "لم يتم الدفع"}</td>
                <td>
                  <button
                    className="btn btn-success"
                    onClick={() => teacherGotPaid(_id)}
                    disabled={gotPaid}
                  >
                    دفع
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}
