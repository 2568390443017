import React from "react";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import styles from "../../css/testimonials.module.css";
export default function Testimonials() {
  return (
    <div className="py-5" style={{ backgroundColor: "#E5EDE5" }}>
      <Container>
        <h2 className="text-center mb-5 testi">آراء العملاء</h2>
        <div className="row row-cols-1 row-cols-md-3 g-4">
          <div className="col">
            <Card className="h-100 shadow-lg shadow testimonial-card">
              <Card.Body>
                <p style={{ direction: "rtl" }} className="card-text">
                  "لاأجد كلمات تعبر عن شكري وتقديري للأكاديمية فجزاهم الله خير
                  الجزاء"
                </p>
                <div className="d-flex justify-content-between align-items-center mt-4">
                  <div>
                    <img
                      src="https://cdn4.iconfinder.com/data/icons/people-2-5/36/113-512.png"
                      className={styles.avatar}
                      alt="user avatar"
                    />
                    <span className="fw-bold">محمد فضل</span>
                  </div>
                  <h5 className="text-muted mb-0">25/4/2023</h5>
                </div>
              </Card.Body>
            </Card>
          </div>
          <div className="col">
            <Card className="h-100 shadow-lg testimonial-card">
              <Card.Body>
                <p style={{ direction: "rtl" }} className="card-text">
                  "معلمتي ممتازة فلكم جزيل الشكر لأنكم عرفتموني بمعلمتي "
                </p>
                <div className="d-flex justify-content-between align-items-center mt-4">
                  <div>
                    <img
                      src="https://th.bing.com/th/id/OIP.fh3THt7pPdVzctV_kezkKwHaHa?w=214&h=214&c=7&r=0&o=5&dpr=1.4&pid=1.7"
                      className={styles.avatar}
                      alt="user avatar"
                    />
                    <span className="fw-bold">إيمان </span>
                  </div>
                  <h5 className="text-muted mb-0">30/1/2023</h5>
                </div>
              </Card.Body>
            </Card>
          </div>
          <div className="col">
            <Card className="h-100 shadow-lg testimonial-card">
              <Card.Body>
                <p style={{ direction: "rtl" }} className="card-text">
                  "أصبحت متقنا لأحكام التجويد في أقل من شهرين شكرا لكم"
                </p>
                <div className="d-flex justify-content-between align-items-center mt-4">
                  <div>
                    <img
                      src="https://cdn4.iconfinder.com/data/icons/people-2-5/36/113-512.png"
                      className={styles.avatar}
                      alt="user avatar"
                    />
                    <span className="fw-bold">عبد الرحمن عادل </span>
                  </div>
                  <h5 className="text-muted mb-0">1/5/2023 </h5>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      </Container>
    </div>
  );
}
