import React from "react";
import PricingCardAr from "./PricingCardAr";

export default function Payments() {
  return (
    <div>
      <PricingCardAr />
    </div>
  );
}
