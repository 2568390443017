import React, { useEffect, useState, useRef  } from 'react'
import "../../../css/chat.css"
import { useParams, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { getAllMessages, getAllConversations } from '../../../redux';
import { format } from "timeago.js";



export default function DashboardChat() {
  const dispatch = useDispatch();
  const { studentId } = useParams();
  // console.log(useLocation())
    const chatContainerRef = useRef(null);
  const { teacher, firstName, lastName } = useLocation().state
  // console.log(teacher)
  // console.log(studentId)
  const [messages, setMessages] = useState([])
  const messagesContainerRef = useRef(null);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messages])

  useEffect(() => {
    const getMessages = async () => {
      const conversation = await dispatch(getAllConversations(studentId)).unwrap();
      // console.log(conversation?.data[0]?._id)
      const msgs = await dispatch(getAllMessages(conversation?.data[0]?._id)).unwrap();
      setMessages([...msgs?.data])
      console.log(messagesContainerRef.current);
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;

    }

    getMessages()
  }, [])


  return (
    <div className="chat-container">
      <div ref={chatContainerRef} className="messages-container">
        {messages?.map((msg) => (
          <div key={msg._id} className={`message ${msg.sender === studentId ? 'user' : 'other'}`}>
            <div>
            <span className={`message-sender ${msg.sender === studentId ? 'user' : 'other'}`}>{msg.sender === studentId ? `${firstName} ${lastName}` : `${teacher?.firstName} ${teacher?.lastName}`}: </span>
            <span className={`message-content ${msg.sender === studentId ? 'user' : 'other'}`}>{msg.text}</span>
            </div>    
            <div className={`message-date ${msg.sender === studentId   ? 'user' : 'other'}`}>
            <span >{format(msg.createdAt)}</span> 
            </div>
          </div>

        ))}

      </div>

    </div>
  )
}