import axios from "axios";
import React, { useEffect, useState } from "react";
import styles from "../../../css/scheduleTable.module.css";
import { Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

import {
  faBookOpen,
  faLink,
  faQuestionCircle,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { createConversation } from "../../../redux";

export default function StudentsTable() {
  // chat
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //////////////////
  let baseURL = "https://api.eshraqalazhar.com/students/teachers";
  const { user } = useAuthContext();
  const teacherID = user.teacher;
  const [students, setStudents] = useState([]);
  useEffect(() => {
    axios
      .get(`${baseURL}/${teacherID}`)
      .then((response) => {
        setStudents(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  //pagination:
  const [CurrentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 5;
  const lastIndex = CurrentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = students.slice(firstIndex, lastIndex);
  const npage = Math.ceil(students.length / recordsPerPage);
  const numbers = [...Array(npage).keys()];

  //////////////// Chat
  const onStartConversation = async (recieverData) => {
    try {
      const data = { senderId: teacherID, recieverId: recieverData.recieverId };
      const res = await dispatch(createConversation(data)).unwrap();
      if (res.success) {
        navigate(`/chat/${res.data._id}`, { state: recieverData });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={styles.parentTable}>
      <Table className={styles.tableDirction}>
        <thead>
          <tr className={styles.head}>
            <th>
              <FontAwesomeIcon className={styles.tableIcon} icon={faBookOpen} />
              المسلسل
            </th>
            <th>
              <FontAwesomeIcon className={styles.tableIcon} icon={faUser} /> اسم
              الطالب
            </th>
            <th>
              <FontAwesomeIcon className={styles.tableIcon} icon={faEnvelope} />{" "}
              الرسائل
            </th>

            <th>
              <FontAwesomeIcon
                className={styles.tableIcon}
                icon={faQuestionCircle}
              />
              السن
            </th>
            <th>
              <FontAwesomeIcon
                className={styles.tableIcon}
                icon={faQuestionCircle}
              />
              النوع
            </th>
            <th>
              <FontAwesomeIcon
                className={styles.tableIcon}
                icon={faQuestionCircle}
              />
              البلد
            </th>
            <th>
              <FontAwesomeIcon className={styles.tableIcon} icon={faLink} />
              لينك الحصة
            </th>
          </tr>
        </thead>
        <tbody>
          {records.map((student, index) => {
            let {
              _id,
              firstName,
              lastName,
              age,
              gender,
              classZoomLink,
              city,
              country,
              weeklyClasses,
            } = student;
            let daysOfWeek = weeklyClasses.map((weeklyClass) => {
              return `اليوم: ${weeklyClass.dayOfWeek}\nبداية الحصة: ${weeklyClass.startTime}\nالمدة: ${weeklyClass.duration}`;
            });
            return (
              <tr className="" key={index}>
                <td>{index + 1}</td>
                <td>
                  <NavLink
                    className={`${styles.feedbackLink} nav-link`}
                    to={`/teacher/feedbackHistory/${_id}`}
                  >
                    <strong>
                      {firstName} {lastName}
                    </strong>
                  </NavLink>
                </td>
                <td>
                  <button
                    onClick={() =>
                      onStartConversation({
                        recieverId: _id,
                        recieverFirstName: firstName,
                        recieverlastName: lastName,
                      })
                    }
                    className={"chat-icon"}
                  >
                    <strong>
                      <FontAwesomeIcon icon={faEnvelope} className="fs-4" />
                    </strong>
                  </button>
                </td>
                <td>{age}</td>
                <td>{gender == "male" ? "ذكر" : "أنثى"}</td>
                <td>
                  {country}
                  {" - "} {city}
                </td>
                <td>{classZoomLink}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <nav className="d-flex justify-content-center align-items-center">
        <ul className="pagination">
          <li className="page-item">
            <a href="#" className="page-link text-dark" onClick={prePage}>
              السابق
            </a>
          </li>
          {numbers.map((n, i) => {
            return (
              <li
                className={`page-item ${CurrentPage === n + 1 ? "active" : ""}`}
                key={i}
              >
                <a
                  href="#"
                  className="page-link text-dark "
                  onClick={() => changePage(n + 1)}
                >
                  {n + 1}
                </a>
              </li>
            );
          })}

          <li className="page-item">
            <a href="#" className="page-link text-dark" onClick={nextPage}>
              التالي
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
  function prePage(e) {
    if (CurrentPage !== firstIndex + 1) {
      setCurrentPage(CurrentPage - 1);
    }
  }
  function nextPage() {
    if (CurrentPage !== numbers.length) {
      setCurrentPage(CurrentPage + 1);
    }
  }
  function changePage(id) {
    setCurrentPage(id);
  }
}
