import React, { useState, useRef, useEffect, Children } from 'react'
import "../../css/chat.css"
import { io } from "socket.io-client";
import { useParams, useLocation } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { getAllMessages, sendMessage } from '../../redux';
import { useAuthContext } from '../../hooks/useAuthContext';
import { format } from "timeago.js";


export default function Chat() {

  const { user } = useAuthContext();
  const getSenderData = () => {
    if (user?.teacher) return user.teacherObj
    if (user?.student) return user.studentObj
  }
  const dispatch = useDispatch();

  const socket = useRef()
  const msgText = useRef()
  const chatContainerRef = useRef(null);
  const { conversationId } = useParams();
  const { recieverId, recieverFirstName, recieverlastName } = useLocation().state
  const senderData = getSenderData();


  const [messages, setMessages] = useState([]);
  const [onlineUsers, setOnilineUsers] = useState([]);
  const [recievedMessage, setRecievedMessage] = useState();
  const [tr, setTr] = useState();

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {

    socket.current = io("https://api.eshraqalazhar.com");
    socket.current.emit("addUser", senderData._id);
    socket.current.on("getUsers", (users) => {
      setOnilineUsers([...users]);
    });
    try {
      const getMessages = async () => {
        const res = await dispatch(getAllMessages(conversationId)).unwrap();
        setMessages([...res?.data])
      }
      getMessages();
    } catch (error) {
      console.log(error)
    }

    try {
      socket.current.on("recieveMessage", ({ senderId, text }) => {
        setMessages((prevMessages) => {
          const newMessage = {
            conversationId: conversationId,
            sender: senderId,
            text: text,
            createdAt: Date.now(),
          };
          return [...prevMessages, newMessage];
        })
      })
    } catch (error) {
      console.log(error)
    }
  }, [senderData._id]);


  const onSendMessage = async (msgData) => {
    try {
      // Emitting event using socket
      socket.current.emit("sendMessage", {
        senderId: senderData._id,
        recieverId: recieverId,
        text: msgText.current.value
      });
      const res = await dispatch(sendMessage(msgData)).unwrap();
      setMessages([...messages, res?.data])
      msgText.current.value = ''
    } catch (error) {
      console.log(error)
    }
  }


  const handleSendMessage = () => {
    if (msgText.current.value.trim() !== '') {
      const newMessage = {
        conversationId: conversationId,
        sender: senderData._id,
        text: msgText.current.value
      }
      onSendMessage(newMessage)
    }
  };

  return (
    <div className="chat-container">
      <div ref={chatContainerRef} className="messages-container">
        {messages?.map((message, index) => (
          <div
            key={index}
            className={`message ${message.sender === senderData._id ? 'user' : 'other'}`}
          >
            <div>
              {/* <span className={`message-sender ${message.sender === senderData._id ? 'user' : 'other'}`}>{message.sender === senderData._id ? `${senderData.firstName} ${senderData.lastName}` : `${recieverFirstName} ${recieverlastName}`}: </span> */}
              <span className={`message-content ${message.sender === senderData._id ? 'user' : 'other'}`}>{message.text}</span>
            </div>
            <div className={`message-date ${message.sender === senderData._id ? 'user' : 'other'}`}>
            <span >{format(message.createdAt)}</span>
            </div>
          </div>
        ))}
      </div>
      <div className="chat-input-container">
        <button onClick={handleSendMessage}>أرسل</button>
        <input
        className='chat-input'
          ref={msgText}
          type="text"
          placeholder="اكتب رسالتك ..."
        />
      </div>
    </div>
  )
}
